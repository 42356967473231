import React from "react";
import PurpleMountain from '../../assets/icons/purpleMountain.png';
import PenGray from '../../assets/icons/penGray.png';
import { SectionForm, SectionTitle, InstructionText, LogoImageContainer, PurpleDot, ImageInputType, EditButton, EmptyImageContainer, LogoImage } from './stylingForm'
import { onFileChange } from '../../services/collections/imageToFile'

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setImagesInfo, selectImagesInfo } from '../../services/collections/imageCollectionSlice';

interface widgetProps { collectionData: any, updateCollectionAttributes: Function }

export default function Logo(props: widgetProps) {
    let dispatch = useAppDispatch();
    let imagesCollectionContext = useAppSelector(selectImagesInfo);

    let { collectionData, updateCollectionAttributes } = props;
    let updateLogoImage = (event: any) => {
        updateCollectionAttributes("LogoImage", URL.createObjectURL(event.target.files[0]));

        onFileChange(event).then((response: any) => {
            let temporalContext:any = { ...imagesCollectionContext.data }
            temporalContext.logoImage = response[0].file;
            dispatch(setImagesInfo(temporalContext))
        }).catch((error) => {
            console.log("error: " + error)
        })
    }

    let clickLogo = () => { document.getElementById("LogoImageFile")?.click() }
    let logoImage = <LogoImage src={collectionData.LogoImage} alt="Logo-Image" />
    let smallMountain = (id: string) => {
        return (
            <EmptyImageContainer>
                <img src={PurpleMountain} alt="EmptyImage" width="30px" height="30px" onClick={clickLogo} />
            </EmptyImageContainer>
        )
    }

    return (
        <SectionForm>
            <SectionTitle>Logo image <PurpleDot /></SectionTitle>
            <InstructionText>This image will also be used for navigation. 350 x 350 recommended.</InstructionText>
            <LogoImageContainer>
                {collectionData.LogoImage ? logoImage : smallMountain("LogoImageFile")}
                {!collectionData.LogoImage ? "" : (
                    <EditButton onClick={clickLogo} disabled={false/*collectionData.Launched*/} state={false/*collectionData.Launched*/}>
                        <img src={PenGray} alt="edit" />
                    </EditButton>
                )}
                <ImageInputType type="file" id="LogoImageFile" name="LogoImageFile" onChange={updateLogoImage} />
            </LogoImageContainer>
        </SectionForm>
    );
}