import React from "react";
import WalletForm from "../../components/walletsList/walletForm";
import { useNavigate } from "react-router-dom";
import { createWalletInfo, uploadWalletFoto, TResponseCreate } from "../../services/wallets/walletsReq"
import { AxiosResponse } from 'axios';
import { TWalletData, TImageFiles } from "../../components/walletsList/walletForm";

interface TfunctionParams {
    idWallet?: any,
    walletData: TWalletData,
    walletImageFiles: TImageFiles,
}

export default function NewWallet(props: any) {
    let navigate = useNavigate();


    const handleSave = (parameters: TfunctionParams) => {
        let { walletData, walletImageFiles } = parameters;
        let walletImageFile = walletImageFiles.WalletImageData.file;

        let payloadCreate = {
            "type": "create",
            "wallet_name": walletData.WalletName,
            "wallet_address": walletData.WalletAddress,
            "category": walletData.Category,
        }
        console.log(payloadCreate)

        createWalletInfo({ data: payloadCreate }).then((response: AxiosResponse<TResponseCreate>) => {
            let newWalletId = response.data['wallet_id'];

            //==== Adding new images
            if (walletImageFile) {
                let walletImageType = walletImageFile.type.split("/").pop();
                let walletFotoData = {
                    id: newWalletId, 
                    file_type: walletImageType, 
                    image_name:  "wallet_image", 
                    files: walletImageFile
                }
                uploadWalletFoto(walletFotoData);
            }

            navigate("/wallets");
        }).catch((error) => {
            console.log(error)
        });
    }

    return (
        <div className="newCollection">
            <WalletForm type="new" handleSave={handleSave} />
        </div>
    );
}