import React, { useState, useContext } from "react";
import styled from "styled-components";
import Calendar2 from "../calendaries/Calendar2";
import CustomDropMenu3 from "./customDropMenu3";
import UsedDateContex from '../../context/usedDatesContext2';
import FilterDateContex
    from "../../context/filterDateContext";
function previousMonthDate(date: string) {
    const [day, month, year] = date.split("/");
    if ((+year === 0 || +year === 1) && +month === 1) return `31/12/9999`
    if (+month === 1) return `1/12/${+year - 1}`;
    return `1/${+month - 1}/${year}`;
}

function nextMonthDate(date: string) {
    const [day, month, year] = date.split("/");
    if (+year === 9999 && +month === 12) return `1/1/0001`
    if (+month === 12) return `1/1/${+year + 1}`;
    return `1/${+month + 1}/${year}`;
}

export default function CalendariesModal() {
    let todayDate = new Date();
    let fixedTodayDate = `${+todayDate.getDate()}/${+todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
    let fixedNextMonthDate = nextMonthDate(fixedTodayDate);
    let [dynamicDates, setDynamicDates] = useState({ today: fixedTodayDate, next: fixedNextMonthDate })

    let usedDateContex = useContext(UsedDateContex);
    let filterDateContext = useContext(FilterDateContex);


    const changeMonth = (direction: string) => {

        if (direction == "previous") {
            let currentPrevious = previousMonthDate(dynamicDates.today)
            usedDateContex.createDates(currentPrevious);
            setDynamicDates(prevState => {
                let newToday = previousMonthDate(prevState.today)
                let newNext = prevState.today;
                return { today: newToday, next: newNext }
            })

        }
        if (direction == "next") {
            let currentNext = nextMonthDate(dynamicDates.next);
            let currentAboveNext = nextMonthDate(currentNext);
            usedDateContex.createDates(currentAboveNext);
            setDynamicDates(prevState => {
                let newToday = prevState.next
                let newNext = nextMonthDate(prevState.next);
                return { today: newToday, next: newNext }
            })
        }
        filterDateContext.updateData({ ...filterDateContext.Data });
    }

    return (
        <CalendariesModalApp className="calendar-modal">
            <CalendariesSection>
                <Calendar2 inputDate={dynamicDates.today} position="first" changeMonth={changeMonth} />
                <Calendar2 inputDate={dynamicDates.next} position="last" changeMonth={changeMonth} />
            </CalendariesSection>

            <InfoSection>
                <LineRef>
                    <SquareBox border="1px solid #CACACA" background="#FFFFFF" />
                    <p>All bungalows available</p>
                </LineRef>
                <LineRef>
                    <SquareBox border="none" background="#F4F3E6" />
                    <p>Several bungalows available</p>
                </LineRef>
                <LineRef>
                    <SquareBox border="none" background="rgba(250, 183, 131, 0.65)" />
                    <p>Almost full</p>
                </LineRef>
            </InfoSection>

            <YearSection>
                <CustomDropMenu3 selected="" type="text" options={[]} updateSelected={() => { }} dropFlag={false} updateDropFlag={() => { }} />
            </YearSection>
        </CalendariesModalApp>
    );
}


const CalendariesModalApp = styled.div`
    position: absolute;
    top: 110%;
    left: -20%;
    background: #FFFFFF;
    box-shadow: 0px 3.1941px 18.3661px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 20px;
    min-width: 390px;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const CalendariesSection = styled.div`
    display: flex;
    font-size: 12px;
    gap: 30px;
`

const InfoSection = styled.div`
    display: flex;
    justify-content: left;
    gap: 10px;
`

const LineRef = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    & p{
        margin: 0;
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
    }
`

interface StyledSquare { border?: string; background?: string }
const SquareBox = styled.div<StyledSquare>`
    min-width: 22px;
    height: 22px;
    border: ${(props) => props.border};
    background: ${(props) => props.background};
    border-radius: 5px;
`

const YearSection = styled.div`
    width: 80px;
`

const YearView = styled.div`
    
`