import React, {useState} from "react";
import styled from "styled-components";

interface TProps { section: string, changeSection: Function }
export default function ReservationsButtonSection(props: TProps) {
    const {section, changeSection} = props;
    return(
        <ReservationsButtonSectionApp>
            <ButtonSection active={section === "Requests"} onClick={()=>{changeSection("Requests")}}>Requests</ButtonSection>
            <ButtonSection active={section === "Checked"} onClick={()=>{changeSection("Checked")}}>Checked</ButtonSection>
            <ButtonSection active={section === "Canceled"} onClick={()=>{changeSection("Canceled")}}>Canceled</ButtonSection>
        </ReservationsButtonSectionApp>
    );
}


const ReservationsButtonSectionApp = styled.div`
    display: flex;
    justify-content: center;
    gap: 60px;
    padding: 5px;
`

const ButtonSection = styled.div<{active:boolean}>`
cursor: pointer;
    text-align: center;
    width: 100%;
    flex-basis: 90px;
    font-size: 19px;
    color: ${({active}) => active? "#82004C": "#777777"};
    border-bottom: ${({active}) => active? "2px solid #82004C": "none"};
    font-family: "trade-gothic";
    padding-bottom: 5px;
    transition: 0.2s color linear;
`