import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { localReservationsRequest, reservationsRequest } from "./reservationsReq";

const initialReservationState: any = {
    data: [],
    status: 'idle',
    error: null
}


export const fetchReservations =  createAsyncThunk("reservations/fetchReservations", async (data:any)=>{
    try{
        const response = await reservationsRequest(data.startDate, data.endDate);
        //const response = await localReservationsRequest();
        return response.data;
    } catch (err: any){
        return err.message;
    }
})

export const reservationsSlice = createSlice({
    name: "reservations",
    initialState: initialReservationState,
    reducers: {
        clearReservations: (state, action) => {
            //console.log("clearing data")
            state.data = []
        }
    }, 
    extraReducers: (builder) => {
        builder
            .addCase(fetchReservations.fulfilled, (state, action)=> {
                state.data = action.payload;
                state.status = 'fullfiled';
            })
            .addCase(fetchReservations.rejected, (state,action)=>{
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
})

export const getApiReservations = (state:any) => state.reservations.data;
export const getApiReservationsStatus = (state:any) => state.reservations.status;
export const getApiReservationsError = (state:any) => state.reservations.error;

export const {clearReservations} = reservationsSlice.actions

export default reservationsSlice.reducer;