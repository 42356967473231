import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Arrow from '../../assets/icons/arrow2.png'
import UsedDateContex from '../../context/usedDatesContext2';
import CurrentDate from '../../context/currentDateContext'
import { oldDateCheck } from '../calendaries/Day';
import { getDate } from '../calendaries/Calendar';
import CloseImage from '../../assets/icons/closeGrey.png'
import InfoToolTip from './InfoToolTip'

interface widgetProps {
    onSubmit: Function,
    formStatus: boolean,
    changeFormStatus: Function
}

const fixeDateFormat = (testDate: string) => {
    let [cday, cmonth, cyear] = testDate.split("/");
    return `${Number(cday)}/${Number(cmonth)}/${Number(cyear)}`
}

const checkDateInput = (testDate: string) => {
    const validDate = new RegExp(`^\\s*\\d\{1,2}/\\d{1,2}/\\d{4}\\s*$`);
    if (testDate.length > 0 && testDate.match(validDate)) {
        let [cday, cmonth, cyear] = testDate.trim().split("/");
        if (Number(cday) > 0 && Number(cmonth) > 0) {
            return true;
        }
    }
    return false;
}

const getRangeList = (initialNumber: number, endNumber: number) => {
    if (initialNumber < endNumber) {
        let resultList = [];
        for (let i = initialNumber; i <= endNumber; i++) {
            resultList.push(i);
        }
        return resultList;
    }
    return [initialNumber]
}

export default function FormCalendar(props: widgetProps) {
    let d = new Date();
    let dateInfo = getDate(d);
    let globalDate = `${dateInfo.date}/${dateInfo.indexMonth + 1}/${dateInfo.year}`
    let navigate = useNavigate();
    let usedDateContex = useContext(UsedDateContex);
    let currentDateContext = useContext(CurrentDate);
    let { StartDate, EndDate, Persons, OneDay, Bungalows } = currentDateContext.Data;
    let [startDateValue, setStartDateValue] = useState(StartDate);
    let [endDateValue, setEndDateValue] = useState(EndDate);
    let [personsValue, setPersonsValue] = useState(Persons||1);
    let [bungalowsValue, setBungalowsValue] = useState(Bungalows||1);
    let [oneDayCheck, setOneDayCheck] = useState(OneDay);
    let [listNumberBungalows, setListNumberBungalows] = useState(getRangeList(Math.ceil(Persons / 2.0), Persons));
    let [warningClass, serWarningClass] = useState({ start: false, end: false });
    let [reserveButtonState, setReserveButtonState] = useState(false);
    let [termsModalState, setTermsModalState] = useState(false)

    useEffect(() => {
        setStartDateValue(StartDate);
        setEndDateValue(EndDate);
        let validDates = StartDate && checkDateInput(StartDate) && EndDate && checkDateInput(EndDate)
        if (validDates) {
            setReserveButtonState(true)
        } else {
            if (reserveButtonState) setReserveButtonState(false)
        }
    }, [StartDate, EndDate])

    useEffect(() => {
        setPersonsValue(Persons);
        setBungalowsValue(Bungalows);
        setOneDayCheck(OneDay);
        setListNumberBungalows(getRangeList(Math.ceil(Persons / 2.0), Persons))
    }, [Persons, Bungalows])
    



    const startDateHandler = (event: any) => { serWarningClass((x) => { return { ...x, start: false } }); setStartDateValue(event.target.value) }
    const endDateHandler = (event: any) => { serWarningClass((x) => { return { ...x, end: false } }); setEndDateValue(event.target.value) }
    const personsHandler = (event: any) => {
        let guests = Number(event.target.value.match(/\d/)[0]);
        setPersonsValue(guests);
        let newListBungalows = getRangeList(Math.ceil(guests / 2.0), guests);
        setListNumberBungalows([...newListBungalows])
        currentDateContext.updateData({ ...currentDateContext.Data, Persons: guests, Bungalows: newListBungalows[0] })
    }
    const oneDayHandler = () => {
        if (oneDayCheck) {
            setOneDayCheck(false);
            setEndDateValue("")
            currentDateContext.updateData({ ...currentDateContext.Data, EndDate: "", OneDay: false })
        } else {
            setOneDayCheck(true);
            setEndDateValue(startDateValue)
            currentDateContext.updateData({ ...currentDateContext.Data, EndDate: StartDate, OneDay: true })
        }
    }
    const boungalowsHandler = (event: any) => {
        let nboungalows = Number(event.target.value.match(/\d/)[0]);
        setBungalowsValue(nboungalows)
        currentDateContext.updateData({ ...currentDateContext.Data, Bungalows: nboungalows, MaxDate: "" })
    }



    const startDateBlur = (event: any) => {
        serWarningClass((x) => { return { ...x, start: false } });
        let newDate = { ...currentDateContext.Data };
        let dateFormIsValid = checkDateInput(event.target.value);
        if (dateFormIsValid) {
            let correctedDate = fixeDateFormat(event.target.value);
            currentDateContext.updateData({ ...currentDateContext.Data, StartDate: correctedDate })

            if (!oldDateCheck(endDateValue, correctedDate) && !oldDateCheck(correctedDate, globalDate)) {

                if (!oneDayCheck) {
                    newDate.StartDate = correctedDate || "";
                    if (correctedDate === EndDate) {
                        newDate.EndDate = ""
                    }
                    currentDateContext.updateData(newDate);
                }

                else if (correctedDate !== EndDate) {
                    if (!correctedDate.length) setStartDateValue(EndDate)
                    currentDateContext.updateData({ ...currentDateContext.Data, StartDate: correctedDate || EndDate, EndDate: correctedDate || EndDate });
                }

            }
            else {
                setStartDateValue("")
                currentDateContext.updateData({ ...currentDateContext.Data, StartDate: "" });
                serWarningClass((x) => { return { ...x, start: true } });
            }
        }

        else {
            serWarningClass((x) => { return { ...x, start: true } });
        }
    }
    const endDateBlur = (event: any) => {
        serWarningClass((x) => { return { ...x, end: false } });
        let newDate = { ...currentDateContext.Data };
        let dateFormIsValid = checkDateInput(event.target.value);

        if (dateFormIsValid) {
            let correctedDate = fixeDateFormat(event.target.value);
            currentDateContext.updateData({ ...currentDateContext.Data, EndDate: correctedDate })

            if (!oldDateCheck(correctedDate, startDateValue) && !oldDateCheck(correctedDate, globalDate)) {

                if (!oneDayCheck) currentDateContext.updateData({ ...currentDateContext.Data, EndDate: correctedDate });

                if (!oneDayCheck) {
                    newDate.EndDate = correctedDate || "";
                    if (correctedDate === StartDate) {
                        newDate.StartDate = ""
                    }
                    currentDateContext.updateData(newDate);
                }

                else if (correctedDate !== StartDate) {
                    if (!correctedDate.length) setEndDateValue(StartDate)
                    currentDateContext.updateData({ ...currentDateContext.Data, StartDate: correctedDate || StartDate, EndDate: correctedDate || StartDate });
                }
            }
            else {
                setEndDateValue("")
                currentDateContext.updateData({ ...currentDateContext.Data, EndDate: "" });
                serWarningClass((x) => { return { ...x, end: true } });
            }
        }
        else {
            serWarningClass((x) => { return { ...x, end: true } });
        }
    }


    const submitHandler = (event: any) => {
        event.preventDefault();
        if (props.formStatus) props.changeFormStatus()

        if (checkDateInput(event.target[0].value) && checkDateInput(event.target[1].value)) {
            let newBetween = [...currentDateContext.Data.BetweenDays]
            let correctedBetween = newBetween.filter((element, index) => index === newBetween.indexOf(element))
            let coorectedStartDate = fixeDateFormat(event.target[0].value);
            let correctedEndDate = fixeDateFormat(event.target[1].value);
            let correctedPersons = Number(event.target[2].value.match(/\d+/)[0]);
            let correctedNumBugalow = Number(event.target[3].value.match(/\d+/)[0])
            setStartDateValue(coorectedStartDate);
            if (oneDayCheck) {
                setEndDateValue(coorectedStartDate);
                correctedEndDate = coorectedStartDate;
            }
            else {
                setEndDateValue(correctedEndDate)
            }
            const formOutput = {
                StartDate: coorectedStartDate,
                EndDate: correctedEndDate,
                Persons: correctedPersons,
                OneDay: oneDayCheck,
                BetweenDays: correctedBetween,
                Bungalows: correctedNumBugalow
            }

            props.onSubmit(formOutput)
        }
    }

    const cancelHandler = (event: any) => {
        event.preventDefault();
        navigate("/reservations")
    } 

    return (
        <FomrCalendarMain formStatus={props.formStatus}>

            <FormContainer>

                {props.formStatus ? <ExitButton onClick={() => { props.changeFormStatus() }}><img src={CloseImage} /></ExitButton> : ''}
                <Title>Reserve Your Visit</Title>
                <Description>Click on one of the dates enabled to make your reservation of the bungalows or a day visit</Description>

                <Form onSubmit={submitHandler}>
                    <DatesSection>
                        <StartDateDiv>
                            <label htmlFor="StartDate">Start date</label>
                            <input id="StartDate" type="text" value={startDateValue} onChange={startDateHandler} onBlur={startDateBlur} style={warningClass.start ? { "border": "1px solid #ff3b3b" } : {}} />
                        </StartDateDiv>
                        <EndDateDiv>
                            <label htmlFor="EndDate">End date</label>
                            <input id="EndDate" type="text" value={endDateValue} onChange={endDateHandler} onBlur={endDateBlur} style={warningClass.end ? { "border": "1px solid #ff3b3b" } : {}} />
                        </EndDateDiv>
                    </DatesSection>
                    <CustomDropDown>
                        <label htmlFor="Guests">Number of Guests</label>
                        <select id="Guests" onChange={personsHandler} value={`${personsValue} guest${personsValue == 1 ? '' : "s"}`}>
                            <option>1 guest</option>
                            <option>2 guests</option>
                            <option>3 guests</option>
                            <option>4 guests</option>
                            <option>5 guests</option>
                            <option>6 guests</option>
                        </select>
                    </CustomDropDown>
                    
                    {oneDayCheck? "" : (
                    <CustomDropDown>
                        <label htmlFor="Bungalows">Number of bungalows</label>
                        <InfoToolTip message="Maximum occupancy per bungalow is 2 people, the usage fee is 150USD per bungalow and night." />
                        <select id="Bungalows" onChange={boungalowsHandler} value={`${bungalowsValue} Bungalow${bungalowsValue == 1 ? '' : "s"}`} className={`${bungalowsValue} Bungalow${bungalowsValue == 1 ? '' : "s"}`}>
                            {listNumberBungalows.map((element) => {
                                return (
                                    <option key={element}>{element} Bungalow{element == 1 ? '' : "s"}</option>
                                );
                            })}
                        </select>
                    </CustomDropDown>)}

                    <OneDayCheck>
                        <label htmlFor="OneDayCheck">Only day visit</label>
                        <input id="OneDayCheck" type="checkBox" onChange={oneDayHandler} checked={oneDayCheck} />
                    </OneDayCheck>

                    <EditButton typeof="submit" disabled={!reserveButtonState} enabledButton={reserveButtonState}>Edit</EditButton>
                    <CancelButton onClick={cancelHandler}>Cancel</CancelButton>

                </Form>
            </FormContainer>
        </FomrCalendarMain>
    );
}




const FomrCalendarMain = styled.div<{ formStatus: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: all 0.3s linear;
    
    @media only screen and (max-width: 710px){
        width: 100%;
        bottom: ${(props) => props.formStatus ? "0" : "-710px"};
        position: fixed;
        z-index: 1000;
        max-height: 105vh;
        left:0;
    }
    
`

const FormContainer = styled.div`
    //padding: 40px 30px;
    background: #F0EEDD;
    border-radius: 16px;
    padding: 30px;
    width: 300px;
    @media only screen and (max-width: 710px){
        width: 100%;
    }
    
`

const ExitButton = styled.div`
    object-fit: contain;
    border: none;
    width: 20px;
    height: 20px;
    display: none;
    margin-left: auto;
    //background: url(${CloseImage});
    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 710px){
        display: block
    }
`


const Form = styled.form`
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;
    & label{
        padding-left: 10px;
        color: #BCBCBC;
        font-size: 14px;
    };
    & input,
    & select{
        border: none;
        border-radius: 10px;
        padding: 9px 12px;
        cursor: pointer;
        color: #777777;
    };
`

const Title = styled.h3`
    font-family: 'trade-gothic';
    font-style: normal;
    font-size: 22px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
    margin-bottom: 10px;
`
const Description = styled.p`
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: left;
    font-family: 'trade-gothic';
    font-style: normal;
    font-size: 15px;
    line-height: 25px;
    text-decoration: none;
    color: #777777;

`
const DatesSection = styled.div`
    display: flex;
    gap: 15px;
    padding-bottom: 8px;
`
const StartDateDiv = styled.div`
    font-family: 'trade-gothic';
    font-size: 17px;
    flex-grow: 1;
    & input{
        width: 100%;
        font-size: 14px;
        box-sizing: border-box;
    }
`
const EndDateDiv = styled.div`
    box-sizing: border-box;
    font-family: 'trade-gothic';
    font-size: 17px;
    flex-grow: 1;
    & input{
        font-size: 14px;
        width: 100%;
        box-sizing: border-box;
    }
`

const CustomDropDown = styled.div`
    font-family: 'trade-gothic';
    font-size: 17px;
    padding-bottom: 8px;
    & select{
        width: 100%;
        appearance: none;
        background: url(${Arrow}), #FFFFFF;
        background-repeat:no-repeat;
	    background-size: 10px 7px;
	    background-position: 95% 50%;
        font-size: 14px;
    }
`

const OneDayCheck = styled.div`
    font-family: 'trade-gothic';
    font-size: 17px;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;    
    & input{
        border: 1px solid #830F5B;
    }
`

const EditButton = styled.button<{ enabledButton: boolean }>`
    text-transform: uppercase;
    font-family: 'trade-gothic';
    font-size: 15px;
    padding: 12px 40px;
    border: none;
    background: ${({ enabledButton }) => enabledButton ? "#84004C" : "#747474"};
    transition: all 0.5s linear;
    border-radius: 12px;
    color: #FFFFFF;
    cursor: ${({ enabledButton }) => enabledButton ? "pointer" : "default"};
`

const CancelButton = styled.button`
    text-transform: uppercase;
    font-family: 'trade-gothic';
    font-size: 15px;
    padding: 12px 40px;
    border: none;
    background: #F0EEDD;
    border: 1px solid #84004C;
    border-radius: 12px;
    color: #84004C;
    cursor: pointer;

    &:hover{
        background: #fffdef;
    }
`