import React, { FormEvent, useState, useContext, useEffect } from "react";
import { Moment } from "moment";
import styled from "styled-components";
import FormCalendar from "../../components/formCalendar/FormCalendat";
import Calendar from "../../components/calendaries/Calendar";
import Backdrop from "../../components/modals/backdrop";
import ModalReserved from "../../components/modals/modalReserved";
import ModalConfirmation from "../../components/modals/modalConfirmation";


import UsedDateContex from '../../context/usedDatesContext2';
import CurrentDate from '../../context/currentDateContext';
import { DatesType, setAvailableBungalow } from '../../context/usedDatesContext2'
import { getLocalReservation, TLocalReservation, editReservation, TbookingPost, getReservation } from "../../services/reservations/reservationsReq";
import { useNavigate, useParams } from "react-router-dom";

function previousMonthDate(date: string) {
    const [day, month, year] = date.split("/");
    if ((+year === 0 || +year === 1) && +month === 1) return `31/12/9999`
    if (+month === 1) return `1/12/${+year - 1}`;
    return `1/${+month - 1}/${year}`;
}

function nextMonthDate(date: string) {
    const [day, month, year] = date.split("/");
    if (+year === 9999 && +month === 12) return `1/1/0001`
    if (+month === 12) return `1/1/${+year + 1}`;
    return `1/${+month + 1}/${year}`;
}

function getDates(startDate: any, endDate: any) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate < endDate) {
        let auxDate = new Date(currentDate);
        let [aMonth, aDay, aYear] = auxDate.toLocaleDateString().split("/")
        dates.push(`${aDay}/${aMonth}/${aYear}`);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    //if (dates.length) dates.shift();
    return dates;
}


export default function EditReservation() {

    let currentDateContext = useContext(CurrentDate);
    let { StartDate, EndDate, OneDay, BetweenDays } = currentDateContext.Data;
    let reservationIdParam = useParams().id;
    let navigate = useNavigate();
    let [firstLoad, setFirstLoad] = useState(true);

    let todayDate = new Date();
    let fixedTodayDate = `${+todayDate.getDate()}/${+todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
    let fixedNextMonthDate = nextMonthDate(fixedTodayDate);

    let usedDateContex = useContext(UsedDateContex);

    const [dynamicDates, setDynamicDates] = useState({ today: fixedTodayDate, next: fixedNextMonthDate })
    let [daysReserved, setDaysReserved] = useState(0);
    let [formStatus, setFormStatus] = useState(false);
    let [reservationForm, setReservationForm] = useState();

    const [visibleBackdrop, setVisibleBackdrop] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleConfirmationModal, setvisibleConfirmationModal] = useState(false)
    const openModal = () => {
        setVisibleBackdrop(true)
        setvisibleConfirmationModal(false);
        setVisibleModal(true);
    }
    const openConfirmation = () => {
        setVisibleBackdrop(true);
        setVisibleModal(false);
        setvisibleConfirmationModal(true);
    }
    const closeModal = () => {
        setVisibleBackdrop(false);
        setVisibleModal(false);
        setFormStatus(false)
        setvisibleConfirmationModal(false);
        setReservationForm(undefined)
    }
    const closeModalAndRedirect = () => {
        setVisibleBackdrop(false);
        setVisibleModal(false);
        setFormStatus(false)
        setvisibleConfirmationModal(false);
        setReservationForm(undefined)
        navigate("/reservations")
    }
    const openBackDrop = () => { setVisibleBackdrop(true) };
    const closeBackDrop = () => { setVisibleBackdrop(false) };




    const submitFormatDate = (date: string) => {
        let [formDay, formMonth, formYear] = date.split("/");
        formMonth = formMonth.length === 1 ? "0" + formMonth : formMonth;
        formDay = formDay.length === 1 ? "0" + formDay : formDay;
        return `${formYear}-${formMonth}-${formDay}`
    }

    const confirmReservation = () => {
        let formData: any = reservationForm;

        usedDateContex.updateDates(formData)
        let endPointSubmit: TbookingPost = {
            "reservation_start": submitFormatDate(formData.StartDate),
            "reservation_end": submitFormatDate(formData.EndDate),
            "type": formData.OneDay ? "day" : "nights",
            "guests": formData.Persons,
            "bungalows": formData.Bungalows
        }
        currentDateContext.updateData({
            StartDate: "",
            EndDate: "",
            Persons: currentDateContext.Data.Persons,
            Bungalows: currentDateContext.Data.Bungalows,
            OneDay: false,
            MaxDate: "",
            BetweenDays: []
        })

        //===== posting data
        let editApiParams = { reservationId: reservationIdParam, payload: endPointSubmit }
        console.log(editApiParams)
        editReservation(editApiParams).then((response) => {
            console.log("made booking post")
            console.log(editApiParams)
            console.log(response)
            usedDateContex.reloadDates("")
            openModal();
        })
        //console.log("submitting")
        //openModal();
    }

    const onSubmitHandler = (formData: any) => {
        setReservationForm(formData);
        openConfirmation();
    }

    const dateRemove = () => {
        let cleanData = currentDateContext.Data;
        cleanData.StartDate = "";
        cleanData.EndDate = "";
        currentDateContext.updateData(cleanData);
    }

    const changeMonth = (direction: string) => {
        if (direction == "previous") {
            let currentPrevious = previousMonthDate(dynamicDates.today)
            //if (!isMonthInContext(currentPrevious, usedDateContex.dates)) {
            usedDateContex.createDates(currentPrevious);
            //}
            setDynamicDates(prevState => {
                let newToday = previousMonthDate(prevState.today)
                let newNext = prevState.today;
                return { today: newToday, next: newNext }
            })

        }
        if (direction == "next") {
            let currentNext = nextMonthDate(dynamicDates.next);
            let currentAboveNext = nextMonthDate(currentNext);
            //if (!isMonthInContext(currentNext, usedDateContex.dates)) {
            usedDateContex.createDates(currentAboveNext);
            //}
            setDynamicDates(prevState => {
                let newToday = prevState.next
                let newNext = nextMonthDate(prevState.next);
                return { today: newToday, next: newNext }
            })
        }
        currentDateContext.updateData({ ...currentDateContext.Data });
        //usedDateContex.updateDates({ Data: { StartDate: "", EndDate: "", OneDay: true, Persons: 0 } })
        //usedDateContex.reloadDates();
    }

    const changeFormStatus = () => {
        if (formStatus) closeBackDrop();
        if (!formStatus) openBackDrop();
        setFormStatus(prevState => !prevState);
        //getOcupedDates({date:StartDate})
    }


    // determine number of days reserved and use it in title
    if (StartDate && EndDate && !OneDay && !daysReserved) {
        const [sDay, sMonth, sYear] = StartDate.split("/");
        const [eDay, eMonth, eYear] = EndDate.split("/");
        const msPerDay = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(+sYear, +sMonth, +sDay);
        const utc2 = Date.UTC(+eYear, +eMonth, +eDay);
        const difference = Math.floor((utc2 - utc1) / msPerDay);
        setDaysReserved(+difference)
    }
    if ((((!StartDate || !EndDate) && !OneDay) || OneDay) && daysReserved > 0) setDaysReserved(0);
    let calendaryTytle = StartDate ? `${StartDate} - ${EndDate}` : "Select a date to start";
    let fixedReserve = (
        <FixedReservation formStatus={formStatus}>
            <DaysReserved>{daysReserved ? `${daysReserved} Days reserved` : "Select a date to start"}</DaysReserved>
            <ReserveButton onClick={changeFormStatus}>Reserve</ReserveButton>
        </FixedReservation>
    )


    useEffect(() => {
        getReservation(reservationIdParam).then((response: { data: TLocalReservation }) => {
            //getLocalReservation(reservationIdParam).then((response: { data: TLocalReservation }) => {
            let [sDay, sMonth, sYear] = response.data.startDate.split("/");
            let [eDay, eMonth, eYear] = response.data.endDate.split("/");
            let listBetweenDays = getDates(new Date(+sYear, +sMonth - 1, +sDay), new Date(+eYear, +eMonth - 1, +eDay))
            let updatedCurrentDateContext = { ...currentDateContext.Data };
            updatedCurrentDateContext.StartDate = response.data.startDate || "";
            updatedCurrentDateContext.EndDate = response.data.endDate || "";
            updatedCurrentDateContext.Persons = response.data.guests || 1;
            updatedCurrentDateContext.Bungalows = response.data.bungalows || 1;
            updatedCurrentDateContext.OneDay = response.data.oneDay || false;
            updatedCurrentDateContext.BetweenDays = listBetweenDays || [];
            if(updatedCurrentDateContext.StartDate === updatedCurrentDateContext.EndDate) updatedCurrentDateContext.OneDay = true;
            console.log(updatedCurrentDateContext)
            currentDateContext.updateData(updatedCurrentDateContext);
        }).catch((error) => {
            console.log(error)
        })
    }, [])


    //adding the reserved dates to API results temporally, 
    useEffect(() => {
        let usedKeys = usedDateContex.dates ? Object.keys(usedDateContex.dates).length : undefined

        if (firstLoad && StartDate && usedKeys && !OneDay) {

            let temporalUsedDatesContext = { ...usedDateContex.dates };
            
            if (BetweenDays.length) {

                for (let i = 0; i < currentDateContext.Data.BetweenDays.length; i++) {
                    let [bDay, bMonth, bYear] = currentDateContext.Data.BetweenDays[i].split("/");
                    let temporalDay = temporalUsedDatesContext[bYear][bMonth][bDay];
                    if (temporalDay) {
                        setFirstLoad(false);
                        let selectedDate = { ...temporalDay }
                        if (selectedDate.AvailableBungalows) {
                            let availableBungalows = [...selectedDate.AvailableBungalows];
                            
                            availableBungalows[0].AvailableQuantity = selectedDate.AvailableBungalows[0].AvailableQuantity + currentDateContext.Data.Bungalows;
                            selectedDate.AvailableBungalows = availableBungalows;
                            temporalUsedDatesContext[bYear][bMonth][bDay] = selectedDate;
                        }
                    }
                }
            }
        }

    }, [usedDateContex.dates])


    return (
        <CalendaryMain>
            {visibleBackdrop && <Backdrop onclick={closeModal} />}
            {visibleConfirmationModal && <ModalConfirmation okClick={confirmReservation} cancelClick={closeModal} />}
            {visibleModal && <ModalReserved okClick={closeModalAndRedirect} />}

            <div className="reservations-titleSection">
                <h1 className="reservations-title">Edit Reserve</h1>
            </div>

            <ContentBox>
                {fixedReserve}
                <ReserveContent>
                    <FormCalendar onSubmit={onSubmitHandler} formStatus={formStatus} changeFormStatus={changeFormStatus} />
                </ReserveContent>
                <MainRight>
                    <CalendarTytle>
                        <h3>{calendaryTytle}</h3>
                    </CalendarTytle>
                    <CalendariesContent>
                        <Calendar inputDate={dynamicDates.today} position="first last" changeMonth={changeMonth} />
                    </CalendariesContent>
                    <InfoSection>
                        <ReferenceInfo>
                            <LineRef>
                                <SquareBox border="1px solid #CACACA" background="#FFFFFF" />
                                <p>All bungalows available</p>
                            </LineRef>
                            <LineRef>
                                <SquareBox border="none" background="#F4F3E6" />
                                <p>Several bungalows available</p>
                            </LineRef>
                            <LineRef>
                                <SquareBox border="none" background="rgba(250, 183, 131, 0.65)" />
                                <p>Almost full</p>
                            </LineRef>
                        </ReferenceInfo>
                        <div>
                            <ClearButton onClick={dateRemove}>Clear Dates</ClearButton>
                        </div>
                    </InfoSection>
                </MainRight>
            </ContentBox>
        </CalendaryMain>
    );
}

const CalendaryMain = styled.div`
    width: 100%;
`
const ContentBox = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: space-evenly;
    max-width: 1500px;
    margin: auto;
    @media only screen and (max-width: 710px){
        justify-content: space-evenly;
    }
`

const ReserveContent = styled.div`
    //flex-basis: 450px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    flex-grow: 1;
    @media only screen and (max-width: 1000px){
        flex-grow: 1;
    }

    @media only screen and (max-width: 710px){
        box-sizing: content-box;
        position: absolute;
        width: 100%;
    }
`

const FixedReservation = styled.div<{ formStatus: boolean }>`
    display: none;
    
    @media only screen and (max-width: 710px){
        background: #F0EEDD;
        position: relative;
        display: ${(props) => props.formStatus ? "none" : "flex"};
        width: 100%;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
        border-radius: 16px 16px 0px 0px;
        padding: 20px 30px 60px 30px;
        
        position: fixed;
        z-index: 100;
        left:0;
        bottom: 0;
    }
`

const DaysReserved = styled.div`
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
`

const ReserveButton = styled.button`
    text-transform: uppercase;
    font-family: 'trade-gothic';
    font-size: 15px;
    padding: 12px 40px;
    border: none;
    background: #84004C;
    border-radius: 12px;
    color: #FFFFFF;
`

const MainRight = styled.div`
    //flex-basis: 450px;
    flex-grow: 1;
    
`

const CalendarTytle = styled.div`
    margin: auto;
    padding: 10px 5px 10px 13px;
    & h3{
        text-align: left;
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 25px;
        color: #84004C;
        margin: 0;
    }

    @media only screen and (max-width: 1000px){
        max-width: unset;
        min-width: unset;
        padding: 10px 5px 0px 20px;
    }
`

const CalendariesContent = styled.div`
    margin: auto;
    max-width: 660px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 1000px){
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 5px 10px 10px;
        gap: 20px;
        max-width: 100%;
    }

    @media only screen and (max-width: 710px){
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
    }
`

const InfoSection = styled.div`
    margin: auto;
    max-width: 660px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 5px 0px 13px;
    gap: 25px;
    align-items: flex-start;
`

const ReferenceInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    color: #777777;
`

const LineRef = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    & p{
        margin: 0;
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
`

interface StyledSquare { border?: string; background?: string }
const SquareBox = styled.div<StyledSquare>`
    width: 28px;
    height: 28px;
    border: ${(props) => props.border};
    background: ${(props) => props.background};
    border-radius: 5px;
`

const ClearButton = styled.button`
    background: #F0EEDD;
    padding: 6px 16px;
    border: none;
    border-radius: 12px;
    font-family: 'trade-gothic';
    font-style: normal;
    font-size: 17px;
    line-height: 25px;
    cursor: pointer;
    &:hover{
        background: #ebe8d5ea;
    }
`