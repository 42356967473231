import React, { useState } from "react";
import styled from "styled-components";
import './Day3.css'

interface widgetProps {
    date: string,
    state: "Start"|"End"|"Between"|"Normal"|"Selected"|"Unique"|"Disabled"|undefined,
    customStyle: string|undefined|null,
    selectable: boolean,
    clicked: Function,
    children: any
}

export default function Day(props: widgetProps) {
    const {date, state, customStyle, selectable, clicked} = props

    let dayBoxStyle = {
        background: "#FFFFFF",
    }
    let dayNumberStyle = {
        background: "unset",
        color:"#000000",
        cursor: "pointer",
        borderHover: "1px solid #84004C;"
    }

    switch(state){
        case "Start":
            dayBoxStyle.background = "linear-gradient(to right, #FFFFFF 50%, #EDEDED 100%)";
            dayNumberStyle.background = "#84004C";
            dayNumberStyle.color = "#FFFFFF";
            dayNumberStyle.borderHover = "1px solid #ffffff00";
            break;
        case "End":
            dayBoxStyle.background = "linear-gradient(to right, #EDEDED 50%, #FFFFFF 100%)";
            dayNumberStyle.background = "#84004C";
            dayNumberStyle.color = "#FFFFFF";
            dayNumberStyle.borderHover = "1px solid #ffffff00";
            break;
        case "Between":
            dayBoxStyle.background = "#EDEDED"
            dayNumberStyle.borderHover = "1px solid #ffffff00";
            break
        case "Selected":
            dayNumberStyle.background = "#84004C";
            dayNumberStyle.color = "#FFFFFF";
            dayNumberStyle.borderHover = "1px solid #ffffff00";
            break;
        case "Disabled":
            dayNumberStyle.borderHover = "1px solid #ffffff00";
            dayNumberStyle.color = "#a8a8a8;"
            dayNumberStyle.cursor = "default"
            break
    }

    return (
        <DayBox {...dayBoxStyle}>
            <DayNumber {...dayNumberStyle} onClick={() => clicked(date)}>
                {props.children}
            </DayNumber>
        </DayBox>
    );
}


const DayBox = styled.div<{background:string}>`
    margin: 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: ${({background})=> background};
`
const DayNumber = styled.div<{background:string, color:string, cursor:string, borderHover: string}>`
    box-sizing: border-box;
    border-radius: 5px;
    height: 22px;
    width: 24px;
    display: grid;
    justify-content: center;
    align-items: center;
    user-select: none;
    
    background: ${({background})=> background};
    color:${({color})=> color};
    cursor: ${({cursor})=> cursor};
    border: 1px solid #ffffff00;
    &:hover{
        border:${({borderHover})=> borderHover}
    }
`