import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'

interface thisProps { children: JSX.Element, closeModal: () => void, closeComponent?: JSX.Element }
export default function ModalNoIcon(props: thisProps) {
  const { closeComponent, closeModal } = props

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // document.body.style.top = `-${window.scrollY}px`;

    return(() => {
      document.body.style.overflow = 'initial';
      //document.body.style.top = '';
    })
  }, [])

  const escFunction = useCallback((event: any) => {
    if (event.key === "Escape") {
      props.closeModal()
    }
  }, [props.closeModal])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  function hiddeModalFn(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & { id: string}
    const element = event.target as Element
    
    if (element.closest('#closeModalIcon')) {
      props.closeModal()
    }

    if(target.id.match(/modalBackground|closeModalIcon/)) {
      props.closeModal()
    }
  }
  
  return(
    <ModalBackground
      id='modalBackground'
      style={{zIndex: 1001 }}
      onClick={hiddeModalFn}
    >
      <ModalContainer style={{zIndex: 1002 }}>

        { closeComponent &&
          ({ ...closeComponent,
            props: {
              ...closeComponent.props,
              id: 'closeModalIcon',
            }
          })
        }

        { props.children }

      </ModalContainer>
    </ModalBackground>
  )
}

const ModalBackground = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1999;
`
const ModalContainer = styled.div`
  position: relative;
  background-color: transparent;
  margin: auto;
  border-radius: 10px;
  z-index: 1999;
`