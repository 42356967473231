import { pointer } from "@testing-library/user-event/dist/types/pointer";
import React, {useState} from "react";
import styled from 'styled-components'


export const FormCollection = styled.div`
box-sizing: border-box;
padding: 40px 20px 40px 60px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-evenly;
gap: 20px;
width: 100%;
max-width: 900px;
`

export const Title = styled.div`
& h2{
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-transform: uppercase;
    color: #830F5B;
    margin: 0;
}
`

export const RequiredAdd = styled.div`
font-family: 'trade-gothic';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
& p{
    margin: 0;
}
`

export const PurpleDot = styled.span`
&::before{
    color: #830F5B;
    content: "*";
}
`

export const SectionForm = styled.div`
text-align: left;
display: flex;
flex-direction: column;
gap: 20px;
flex: 1;
width: 100%;
position: relative;
`
export const WalletImageContainer = styled.div`
position: relative;
height: 225px;
width: 50%;
min-width: 225px;
border-radius: 10px;
display: flex;
justify-content: center;
align-content: center;
background: #F0F0F0;
`
export const WalletImage = styled.img`
object-fit: cover;
width: 100%;
min-width: 225px;
border-radius: 10px;
`

export const EmptyImageContainer = styled.div<{clickable:boolean}>`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
cursor: ${({clickable})=> clickable? "pointer": "default"};
`

export const SectionTitle = styled.h2`
font-family: 'trade-gothic';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
text-transform: uppercase;
margin: 0;
width: 100%;
`

export const InstructionText = styled.p`
font-family: 'montserrat-regular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
color: #4F4F4F;
margin: 0;
flex-grow: 1;
`

export const EditButton = styled.button<{ state: boolean }>`
font-family: 'trade-gothic';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
color: #4F4F4F;
background: #EDEDED;
border: none;
cursor: ${({ state }) => state ? "default" : "pointer"};
border-radius: 10px;
position: absolute;
bottom: 10px;
right: 10px;
display: flex;
align-items: center;
gap: 15px;
padding: 9px 10px;
& img{
    width: 20px;
};

&:hover{
    background: ${({ state }) => state ? "#EDEDED" : "#ededede4"};
}
`

export const LogoImageContainer = styled.div`
position: relative;
width: 150px;
height: 150px;
border-radius: 50%;
background: #F0F0F0;
& button{
    bottom: 0;
    right: 0;
}
`
export const LogoImage = styled.img`
display: block;
width: 100%;
height: 100%;
border-radius: 50%;
object-fit: cover;
`

export const TwoSectionSplit = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
//gap: 80px;
gap: 18px;
width: 100%;
flex-wrap: wrap;

/*&>div{
    max-width: 350px;
}*/
`

export const DescriptionText = styled.textarea`
box-sizing: border-box;
width: 100%;
border-radius: 10px; 
border: 1px solid #9B9B9B;
resize: none;
color: #4F4F4F;
padding: 12px 15px;
font-family: 'montserrat-regular';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
`

export const TextInputArea = styled.input<{ withLogo: boolean }>`
box-sizing: border-box;
border: 1px solid #9B9B9B;
border-radius: 10px;
padding: 12px 15px;
padding-right: ${({ withLogo }) => withLogo ? "40px" : "15px"};
width: 100%;
font-family: 'montserrat-regular';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
color: #4F4F4F;

&::-webkit-inner-spin-button, 
&::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
}
`

export const Draft = styled.div`
display: flex;
width: 95%;
align-items: center;
justify-content: flex-end;
gap: 18px;
`
export const DraftText = styled.p<{ state: boolean }>`
color: ${({ state }) => state ? "#84004C" : "#9B9B9B"};
font-family: 'trade-gothic';
font-style: normal;
font-weight: 700;
font-size: 26.5497px;
line-height: 32px;
margin: 0px;
`

export const ButtonsSection = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 20px;
flex-wrap: wrap;
margin-top: 20px;
`

export const buttonBackground = ["", "#84004C", "#FFFFFF"]
export const buttonBorder = ["", "1px solid#84004C", "1px solid #830F5B"];
export const buttonColor = ["", "#FFFFFF", "#830F5B"];
export const buttonHBackground = ["", "#84004deb", "#f3f3f3"]
export const CustomButton = styled.button<{ option: number }>`
width: 100%;
box-sizing: border-box;
background: ${({ option }) => buttonBackground[option]};
border: ${({ option }) => buttonBorder[option]};
color: ${({ option }) => buttonColor[option]};
border-radius: 10px;
padding: 15px 20px;
font-family: 'trade-gothic';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 25px;
text-align: center;
text-transform: uppercase;
cursor: pointer;
flex:1;
justify-content: space-evenly;
min-width: 100px;


&:hover{
    background: ${({ option }) => buttonHBackground[option]};;
}
`


export const PriceCurrency = styled.div`
position: absolute;
right: 15px;
bottom: 11px;
font-family: 'trade-gothic';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 25px;
text-align: center;
color: #4F4F4F;
`

export const PercentSymbol = styled.div`
position: absolute;
right: 15px;
bottom: 13px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
color: #4F4F4F;
`
export const ImageInputType = styled.input`
display: none;
`

export const ErrorMessage = styled.p`
margin: 0;
color: #c71111;
flex: 1;
text-align: left;
`