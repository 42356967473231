import React, { useEffect } from "react";
import EventForm from "../../components/eventsList/eventForm";
import { useNavigate } from "react-router-dom";
import { createEventInfo, getPresignedUrl, TResponseCreate, uploadImage, TuploadEventFotoResponse } from "../../services/events/eventsReq"
import { AxiosResponse } from 'axios';
import { TEventData, TImageFiles } from "../../components/eventsList/eventForm";
import { useAppDispatch } from "../../app/hooks";
import { setLogEvent } from "../../components/popUp/popUpSlice";

interface TfunctionParams {
    idEvent?: any,
    eventData: TEventData,
    eventImageFiles: TImageFiles,
}

export default function NewEvent(props: any) {
    let navigate = useNavigate();
    const dispatch = useAppDispatch()

    const handleSave = (parameters: TfunctionParams) => {
        let { eventData, eventImageFiles } = parameters;
        let eventImageFile = eventImageFiles.EventImageData.file;
        
        let payloadCreate = {
            "type": "create",
            "event_name": eventData.EventName,
            "date": eventData.Date,
            "desc": eventData.Description,
            "tipo": eventData.Type,
            "status": eventData.Status? "launched": "draft"
        }
        
        createEventInfo({ data: payloadCreate }).then((response: AxiosResponse<TResponseCreate>) => {
            let newEventId = response.data['event_id'];
            
            //==== Adding new images
            if (eventImageFile) {
                let eventImageType = eventImageFile.type.split("/").pop();
                let eventFotoData = {
                    event_id: newEventId, 
                    file_type: eventImageType, 
                    image_type: "main", 
                    files: eventImageFile
                }
                getPresignedUrl(eventFotoData).then((response: AxiosResponse<TuploadEventFotoResponse>) => {
                    if (response.data.result === 'success') {
                        uploadImage(eventImageFile, response.data.urlS3Image)
                        .then((resp) => {
                            dispatch(setLogEvent({type: 'info', message: 'Event created successfully'}))
                            navigate("/events");
                        }).catch((error: any) => {
                            console.log(error)
                            dispatch(setLogEvent({type: 'error', message: error.response.data.message }))
                        })
                    }
                }).catch((error: any) => {
                    console.log(error)
                    dispatch(setLogEvent({type: 'error', message: error.response.data.message }))
                })
            } else {
                navigate("/events");
            }

            
        }).catch((error: any) => {
            console.log(error)
            dispatch(setLogEvent({type: 'error', message: error.response.data.message }))
        });
    }

    return (
        <div className="newCollection">
            <EventForm type="new" handleSave={handleSave} />
        </div>
    );
}