import React from 'react'
import styled from 'styled-components'

export default function NotFound404() {

  return(
    <NotFoundContainer className='view'>
      <Code> 404 </Code>
      <p> Under Maintenance </p>
    </NotFoundContainer>
  )
}

const Code = styled.p`
  font-family: Montserrat;
  font-size: 80px;  
  margin-bottom: 10px;
`

const NotFoundContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 30px;
`