import React, { MouseEventHandler, useContext } from "react";
import styled from "styled-components";
import ReactDOM from 'react-dom'
import CurrentDate from '../../context/currentDateContext';

interface BackdropType {
    okClick: MouseEventHandler,
    cancelClick: MouseEventHandler
}

export default function ModalConfirmation(props: BackdropType) {
    const currentDate = useContext(CurrentDate);
    const reservedDays = currentDate.Data.BetweenDays.length + 1;
    const guests = currentDate.Data.Persons;
    const amountOfBungalows = currentDate.Data.Bungalows;
    const oneDayEnabled = currentDate.Data.OneDay;
    const rootBackDrop = document.getElementById('modals');
    
    const normalReservationText = `Please consider that a fee of 150$ per bungalow and night will be applied, your total to pay will be ${150*amountOfBungalows*reservedDays}$us upon arrival to the vineyard, cash is highly encouraged for the payment method`;
    const oneDayReservationText = `Please confirm your visit for ${guests} guest(s) with no bungalow reservation`
    
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <ModalContainer>
                    <BoxMain>
                        <Title>Reservation</Title>
                        <Message>{oneDayEnabled? oneDayReservationText: normalReservationText}</Message>
                        <ButtonSection>
                        <OkButton onClick={props.okClick}>Confirm</OkButton>
                        <CancelButton onClick={props.cancelClick}>Cancel</CancelButton>
                        </ButtonSection>
                    </BoxMain>
                </ModalContainer>,
            rootBackDrop as Element)}
        </React.Fragment>
    );
}

const ModalContainer = styled.div`
    position: fixed;    
    z-index: 1001;
    width: 500px;
    left: calc(100vw/2 - 250px);
    top: calc(100vh/2 - 110px);

    @media (max-width: 768px) {
        width: 360px;
        left: calc(100vw/2 - 180px);
    }
`
const BoxMain = styled.div`
    border-radius: 10px;
    background: #F0EEDD;
    text-align: center;
    font-family: 'trade-gothic';
    padding: 32px;
`
const Title = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
`
const Message = styled.p`
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    color: #979696;
    margin: 15px 0px;
`
const ButtonSection = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-evenly;
    margin: 10px 0px;
`

const OkButton = styled.button`
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    background: #84004C;
    color: #FFFFFF;
    text-transform: uppercase;
    
    flex-basis: 150px;
    cursor: pointer;
`

const CancelButton = styled.button`
flex-basis: 150px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    background: #858585;
    color: #FFFFFF;
    text-transform: uppercase;
    cursor: pointer;
`