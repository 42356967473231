import React from "react";
import "./NewCollection.css";
import CollectionForm from '../../components/collection/collectionForm';
import { useNavigate } from "react-router-dom";
import { createCollection } from "../../services/collections/collectionReq";
import { TResponseCreate } from '../../services/collections/collectionReq'
import { uploadFoto } from '../../services/collections/imageToFile';
import { AxiosResponse } from 'axios';
import { setLogEvent } from "../../components/popUp/popUpSlice";
import { useAppDispatch } from "../../app/hooks";

interface TfunctionParams {
    idCollection?: any,
    collectionData: any,
    bannerImage: any,
    logoImage: any,
    additionalImages: any,
    tokenImages: any
}

export default function NewCollection(props: any) {
    const dispatch = useAppDispatch()
    let navigate = useNavigate();

    const handleSave = (parameters: TfunctionParams) => {
        let { collectionData, bannerImage, logoImage, additionalImages, tokenImages } = parameters

        let payloadCreate = {
            "type": "upload_data",
            "collection_name": collectionData.CollectionName,
            "description": collectionData.Description,
            "category": collectionData.Category,
            "creator_wallet": collectionData.CreatorWallet,
            "currencies": collectionData.Currencies.join(","),
            "price": collectionData.Price,
            "royalty_percentage": collectionData.RoyaltyPercentage,
            "royalty_wallet_address": collectionData.RoyaltyWallet
        }

        createCollection({ data: payloadCreate }).then((response: AxiosResponse<TResponseCreate>) => {
            let newCollectionId = response.data['collection_id'];

            //==== Adding ne wimages
            if (bannerImage) {
                let bannerType = bannerImage.file ? bannerImage.file.type.split("/").pop() : bannerImage.type.split("/").pop()
                let bannerFile = bannerImage
                uploadFoto(newCollectionId, bannerType, "banner_image", bannerFile);
            }
            if (logoImage) {
                let tokenType = logoImage.file ? logoImage.file.type.split("/").pop() : logoImage.type.split("/").pop()
                let tokenFile = logoImage.file ? logoImage.file : logoImage
                uploadFoto(newCollectionId, tokenType, "logo_image", tokenFile);
            }


            let filteredAdditionalFiles = additionalImages.filter((element: any, index: number) => element.state === "ToAdd");
            let formatedPayloadAdditionalList = []
            for (let i = 0; i < filteredAdditionalFiles.length; i++) {
                uploadFoto(newCollectionId, filteredAdditionalFiles[i].file.type.split("/").pop(), "additional_image", filteredAdditionalFiles[i].file);
                
                formatedPayloadAdditionalList.push({"uploadFotoParameters":[newCollectionId, filteredAdditionalFiles[i].file.type.split("/").pop(), "additional_image", filteredAdditionalFiles[i].file]})
            }


            //==== Adding new images with info
            let filteredTokenFiles = tokenImages.filter((element: any, index: number) => element.state === "ToAdd")
            let formatedPayloadTokenList = []
            for (let i = 0; i < filteredTokenFiles.length; i++) {
                let formatedPayload = {
                    title: filteredTokenFiles[i].info.title,
                    desc: filteredTokenFiles[i].info.description,
                    amount: filteredTokenFiles[i].info.quantity,
                    "additional_desc": filteredTokenFiles[i].info.additionalDescription
                }
                uploadFoto(newCollectionId, filteredTokenFiles[i].file.type.split("/").pop(), "token_image", filteredTokenFiles[i].file, formatedPayload);

                let controlCall:any = {...formatedPayload}
                controlCall["uploadFotoParameters"] = [newCollectionId, filteredTokenFiles[i].file.type.split("/").pop(), "token_image", filteredTokenFiles[i].file, formatedPayload]
                formatedPayloadTokenList.push(controlCall);
            }

            navigate("/collections");
        }).catch((error) => {
            console.log(error)
            dispatch(setLogEvent({ type: 'error', message: error.response.data.message }))
        });


    }

    return (
        <div className="newCollection">
            <CollectionForm type="new" handleSave={handleSave} />
        </div>
    );
}