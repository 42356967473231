import React, { useState } from "react";
import "./EventCard.css"
import BlackEdit from "../../assets/icons/penBlack.png"
import { NavLink } from "react-router-dom";
import { EmptyImageContainer } from '../eventFormElements/eventStylingForm';
import PurpleEvent from '../../assets/icons/purpleEvent.png';

interface TEventCard {
    cardInfo: {
        id: string,
        EventName: string,
        EventImage: string,
        Description: string,
        Date: string,
        Status: string
    }
}
export default function EventCard(props: TEventCard) {
    const { id, EventName, EventImage, Description, Date, Status } = props.cardInfo;
    let normalMountain = (
        <EmptyImageContainer clickable={false}>
            <img src={PurpleEvent} alt="EmptyImage" width="128px" height="128px" />
        </EmptyImageContainer>
    )

    let eventImageSource = <img src={EventImage} alt={EventName} className="cardImage" />
    return (
        <div className="eventCard">
            <div className="eventCard-Image">
                    {EventImage ? eventImageSource : normalMountain}
                    <NavLink to={`/events/${id}-item`} className="cardEdit">
                        <img src={BlackEdit} alt="editButton" className="cardEdit-image" />
                    </NavLink>
            </div>
            <div className="eventCard-Info">
                <p>{EventName}</p>
                <p>Date: {Date}</p>
                <p>Status: {Status? "Launched": "Draft"}</p>
            </div>
        </div>
    );
}