import React, { useState } from "react";
import "./EventList.css"
import EventCard from "./eventCard";

interface TEventCard {
    id: string,
    EventName: string,
    EventImage: string,
    Description: string,
    Date: string,
    Status: string
}
interface TEventList { apiEvents: Array<any> }
export default function EventList(props: TEventList) {

    let listEvents = props.apiEvents ? props.apiEvents.map((cardInfo: TEventCard, index: number) => {
        return (
            <EventCard cardInfo={cardInfo} key={String(index)} />
        )
    }) : '';

    return (
        <div className="eventListApp">
            {listEvents}
        </div>
    );
}