import React, { useState } from "react";
import SliderCardBox from '../collection/sliderCardBox'
import { SectionForm, SectionTitle, InstructionText, TwoSectionSplit, PurpleDot } from './stylingForm'

interface widgetProps { collectionData: any, updateCollectionAttributes: Function }
export default function ExtraImages(props: widgetProps) {

    let { collectionData, updateCollectionAttributes } = props;

    const updateAdditionalImages = (elements: Array<string>) => { updateCollectionAttributes("AditionalImages", elements) };
    const updateTokenImages = (elements: Array<string>) => { 
        updateCollectionAttributes("TokenImages", elements) 
    };
    return (
        <TwoSectionSplit>
            <SectionForm>
                <SectionTitle>Additional Images</SectionTitle>
                <InstructionText style={{ height: "80px", "maxWidth": "350px" }}>This image will be used for featuring your collection on the homepage.<br />600 x 400 recommended.</InstructionText>
                <SliderCardBox images={collectionData.AditionalImages} section="additional" updateImages={updateAdditionalImages} customDisabled={false/* collectionData.Launched */} />
            </SectionForm>

            <SectionForm>
                <SectionTitle>Token Images <PurpleDot /></SectionTitle>
                <InstructionText style={{ height: "80px" }}>Upload at least one image.<br />600 x 400 recommended.</InstructionText>
                <SliderCardBox images={collectionData.TokenImages} section="token" updateImages={updateTokenImages} customDisabled={false/* collectionData.Launched */} />
            </SectionForm>
        </TwoSectionSplit>
    );
}