import React, { useState, useContext, useEffect } from "react";
import UsedDateContex from '../../context/usedDatesContext2';
import FilterDateContex from "../../context/filterDateContext";
import './Day2.css'

interface widgetProps {
    date: string,
    globalDate: string,
    children: React.ReactNode
}


export const oldDateCheck = (selected: string, referer: string) => {
    let [selectedDay, selectedMonth, selectedYear] = selected.split("/")
    let [referernDay, refererMonth, refererYear] = referer.split("/")
    if ((+selectedDay < +referernDay && +selectedMonth <= +refererMonth && +selectedYear === +refererYear) ||
        (+selectedMonth < +refererMonth && +selectedYear <= +refererYear) ||
        +selectedYear < +refererYear) return true;
    return false;
}

export const filterBungalows = (usedDateDay: any, Persons: number, Bungalows: number, currentDate: any) => {
    let availableBungalows = usedDateDay.AvailableBungalows.filter((bungalow: any) => {
        let { MaxPeople, AvailableQuantity } = bungalow;
        //if (AvailableQuantity && Persons <= MaxPeople) return true;
        //return false;
        if (AvailableQuantity >= Bungalows) {
            return true;
        }
        return false
    })
    return availableBungalows;
}

export default function Day(props: widgetProps) {
    let [day, month, year] = props.date.split("/");
    let usedDateContex = useContext(UsedDateContex);
    let filterDateContext = useContext(FilterDateContex);
    let { StartDate, EndDate } = filterDateContext.Data;


    let [dinamicClass, setDinamicClass] = useState("dayClass")
    let [stateClass, setStateClass] = useState("");

    useEffect(() => {
        let dates = usedDateContex.dates;
        if (dates) {
            let existInUsedDates = dates.hasOwnProperty(year) && dates[year].hasOwnProperty(month) && dates[year][month].hasOwnProperty(day);
            if (existInUsedDates) {
                let { Date, State, AvailableBungalows } = dates[year][month][day];
                if (State === "Full" || AvailableBungalows.length === 0) {
                    setDinamicClass("disabledDay");
                    setStateClass("");
                }
                else if (State === "Almost") setStateClass("almost");
                else if (State === "Several") setStateClass("several")
                else setStateClass("")
            }

            else setStateClass("");
        }
    }, [usedDateContex])




    useEffect(() => {
        let beforeStartDay = StartDate.length ? oldDateCheck(props.date, StartDate) : false;
        let afterStartDay = StartDate.length ? oldDateCheck(StartDate, props.date) : false;
        let beforeEndDay = EndDate.length ? oldDateCheck(props.date, EndDate) : false;
        let afterEndDay = EndDate.length ? oldDateCheck(EndDate, props.date) : false
        let matchStart = (StartDate === props.date);
        let matchEnd = (EndDate === props.date);


        if (!StartDate && !EndDate) {
            setDinamicClass("dayClass");
        }


        if (StartDate && !EndDate) {
            if (beforeStartDay) setDinamicClass("disabledDay");

            if (!beforeStartDay) {
                if (matchStart) setDinamicClass("dayClassUnique");
                if (afterStartDay) setDinamicClass("dayClass");
            }
        }


        if (!StartDate && EndDate) {
            let newData = { ...filterDateContext.Data };
            setDinamicClass("disabledDay");
            newData.StartDate = "";
            newData.EndDate = "";
            filterDateContext.updateData(newData);
        }


        if (StartDate && EndDate) {
            if (StartDate === EndDate) {
                if (matchStart || matchEnd) {
                    setDinamicClass("dayClassUnique");
                }
                else {
                    setDinamicClass("dayClass");
                }
            }

            if (StartDate !== EndDate) {
                if (beforeStartDay) setDinamicClass("dayClass");
                if (afterEndDay) setDinamicClass("dayClass");
                if (matchStart) setDinamicClass("dayClassStart clickable");
                if (matchEnd) setDinamicClass("dayClassEnd clickable");
                if (afterStartDay && beforeEndDay) {
                    setDinamicClass("betweenDays");
                }

            }
        }
    }, [filterDateContext.Data])



    let clickedDate = () => {

        let newData = { ...filterDateContext.Data };
        let start = newData.StartDate;
        let end = newData.EndDate;
        if (start !== props.date || end.length) {

            if (!start.length || (!oldDateCheck(props.date, start) && !end.length) || (start.length && end.length)) {

                if ((!start.length && !end.length) || (start.length && end.length) || (!start.length && end.length)) {
                    newData.StartDate = props.date;
                    newData.EndDate = "";
                    filterDateContext.updateData(newData);
                }

                if (start.length && !end.length) {
                    newData.EndDate = props.date;
                    filterDateContext.updateData(newData);
                }
            }

        }

        if(start === props.date && !end.length){
            newData.StartDate = "";
            newData.EndDate = "";
            filterDateContext.updateData(newData);
        }
    }

    return (
        <div className={`containerDayDiv container__${dinamicClass}`}>
            <div className={`dayDiv ${dinamicClass} ${stateClass}`} onClick={clickedDate}>
                {props.children}
            </div>
            {/* <div>{limitedDay}</div> */}
        </div>
    );
}
