import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import EventForm from "../../components/eventsList/eventForm"
import { updateEventInfo, uploadEventFoto, deleteEvent } from "../../services/events/eventsReq"
import { TEventData, TImageFiles } from "../../components/eventsList/eventForm"
import { AxiosResponse } from "axios"
import { TuploadEventFotoResponse } from "../../services/events/eventsReq"
import { getPresignedUrl, uploadImage } from "../../services/events/eventsReq"
import { useAppDispatch } from "../../app/store"
import { setLogEvent } from "../../components/popUp/popUpSlice"


interface TfunctionParams {
    idEvent?: any,
    eventData: TEventData,
    eventImageFiles: TImageFiles,
}

export default function EditEvent() {
    let navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleSave = (parameters: TfunctionParams) => {
        let { idEvent, eventData, eventImageFiles } = parameters
        let eventImageFile = eventImageFiles.EventImageData.file;
        console.log('eventImageFile: ', eventImageFile)

        let payloadCreate = {
            "event_name": eventData.EventName,
            "date": eventData.Date,
            "desc": eventData.Description,
            "tipo": eventData.Type,
            "status": eventData.Status? "launched": "draft"
        }

        
        //==== Adding new event image
        if (eventImageFile) {
            console.log('Uploading new event image')
            let eventImageType =  eventImageFile.type.split("/").pop();
            console.log('eventImageType: ', eventImageType)
            let eventFotoData = {
                event_id: idEvent, 
                file_type: eventImageType, 
                image_type:  "main", 
            }
            console.log('eventFotoData: ', eventFotoData)
            //uploadEventFoto(eventFotoData);

            getPresignedUrl(eventFotoData).then((response: AxiosResponse<TuploadEventFotoResponse>) => {
                if (response.data.result === 'success') {
                    uploadImage(eventImageFile, response.data.urlS3Image)
                    .then((resp) => {
                        dispatch(setLogEvent({type: 'info', message: 'Event created successfully'}))
                        navigate("/events");
                    }).catch((error: any) => {
                        console.log(error)
                        dispatch(setLogEvent({type: 'error', message: error.response.data.message }))
                    })
                }
            }).catch((error: any) => {
                console.log(error)
                dispatch(setLogEvent({type: 'error', message: error.response.data.message }))
            })
        }

        //==== Updating event specs
        let eventDataPayload = {
            eventId: String(idEvent),
            data: payloadCreate
        }
        console.log(eventDataPayload)
        updateEventInfo(eventDataPayload).then((response:any) => {
            navigate("/events");
        })
    }

    const handleDelete = (id: string) => {
        deleteEvent({ data: id }).then((response) => {
            console.log(response);
            navigate("/events")
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <EventForm type="edit" handleSave={handleSave} handleDelete={handleDelete}/>
    );
}