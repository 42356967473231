import React, { ChangeEvent, EventHandler, useEffect, useState } from "react";
import styled from "styled-components";
import { applicationDataType } from '../../../services/apiSlice/apiTypes'
import { useApproveApplicationMutation, useRejectApplicationMutation } from "../../../services/apiSlice/apiSlice"
import { ApplicactionStepsTypes } from "./Navigator"
import ApplyData from "./applyData"

import loadingGif from '../../../assets/gif/loading_2.gif'

interface TProps {
    applyData: applicationDataType
    changeStep: (step: ApplicactionStepsTypes) => void,
    cleanSelectedApply: () => void,
    closeModal: () => void,
    errorMsg: (msg: string) => void,
}

export default function ApplyModal(props: TProps) {
    const { applyData, changeStep, cleanSelectedApply, closeModal, errorMsg } = props
    const [loading, setLoading] = useState(false)

    const [approveApply, resultApproveApply] = useApproveApplicationMutation()
    const [rejectApply, resultRejectApply] = useRejectApplicationMutation()

    useEffect(() => {
        if (resultApproveApply.isSuccess && resultApproveApply.data) {
            setLoading(false)
            changeStep('approve')
        }

        if (resultApproveApply.error) {
            setLoading(false)
            changeStep('error')
            errorMsg((resultApproveApply.error as any).data.message)
        }
        console.log('data application', applyData);
    }, [resultApproveApply])

    useEffect(() => {
        if (resultRejectApply.isSuccess && resultRejectApply.data) {
            setLoading(false)
            changeStep('reject')
        }

        if (resultRejectApply.error) {
            setLoading(false)
            changeStep('error')
            errorMsg((resultRejectApply.error as any).data.message)
        }

    }, [resultRejectApply])

    return (
        <ApplicationData>

            <Title> Applicaction Data <IdApplication>#{applyData.id_item}</IdApplication> </Title>

            <Body>
                <ApplyData applyData={applyData} />

                <Buttons>
                    {applyData && applyData.status == 'pending' &&
                    <Approve
                        onClick={() => {
                            setLoading(true)
                            approveApply({
                                applyId: applyData.id_item,
                                // type: applyData.whiteList ? 'whitelist' : 'approved'
                                type: 'approved'
                            })
                        }}
                        disabled={loading}
                        //disabled={true}
                    >
                        APPROVE
                    </Approve>
                    }
                    {applyData && applyData.status == 'pending' &&
                    <Reject
                        onClick={() => {
                            setLoading(true)
                            rejectApply({ applyId: applyData.id_item })
                        }}
                        disabled={loading}
                        //disabled={true}
                    >
                        REJECT
                    </Reject>
                    }
                    <Cancel
                        onClick={() => {
                            closeModal()
                            cleanSelectedApply()
                        }}
                        //disabled={loading}
                        
                    >
                        CANCEL
                    </Cancel>

                </Buttons>
            </Body>

            {loading &&
                <Loading>
                    <LoadingIcon src={loadingGif} />
                </Loading>
            }

        </ApplicationData>
    )
}


const ApplicationData = styled.div`
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    color: #676767;
    font-family: 'trade-gothic';
    max-height: 650px;
    background: #F0EEDD;
    padding: 30px 70px 30px 70px;
    border-radius: 10px;
    text-align: left;

`

const Title = styled.div`
    font-style: normal;
    /* font-weight: 700; */
    font-size: 29px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
    margin: 5px;
    padding: 10px;
    text-align: left;
    width: 100%;
`

const IdApplication = styled.label`
    font-size: 20px;
    color: #676767;
`

const Body = styled.div`
    /* display: flex; */
    gap: 50px;
    
`

const Buttons = styled.div`
    display: flex;
    margin-top: 20px;
    /* flex-direction: column; */
    justify-content: flex-start;
    gap: 10px;
    padding-top: 30px;
`

const Button = styled.button`
    font-family: 'trade-gothic';
    font-weight: 600;
    font-size: 20px;
    width: 200px;
    height: 50px;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
`
const Approve = styled(Button)`
    font-family: 'trade-gothic';
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    width: 200px;
    height: 50px;
    border: 1px solid #84004C;
    border-radius: 5px;
    background-color: #84004C;
    cursor: pointer;
    &:hover {
    background-color: #84004ccf;
    color: #fff;
    }
`
const Reject = styled(Button)`
    font-family: 'trade-gothic';
    color: #84004C;
    font-weight: 600;
    font-size: 20px;
    width: 200px;
    height: 50px;
    border: 1px solid #84004C;
    border-radius: 5px;
    background-color: #F0EEDD;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
    background-color: #ff009321;
    }
`
const Cancel = styled(Button)`
    font-family: 'trade-gothic';
    color: #84004C;
    font-weight: 600;
    font-size: 20px;
    width: 200px;
    float: right;
    height: 50px;
    border: 1px solid #84004C;
    border-radius: 5px;
    background-color: #F0EEDD;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
    background-color: #ff009321;
    }
`

const Loading = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #ffffffbb;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const LoadingIcon = styled.img`
    height: 70px;
`