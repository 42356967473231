import React, { useState } from "react";
import CustomDropMenu from '../collection/customDropMenu'
import { SectionForm, SectionTitle, TwoSectionSplit, PurpleDot, TextInputArea } from './walletStylingForm'
import {TWalletData} from '../walletsList/walletForm'

/*const categoriesOptions = [
    {
        name: "Wine",
        active: true
    },
    {
        name: "Liquor",
        active: true
    },
    {
        name: "Merchandise",
        active: true
    },
    {
        name: "Collectible",
        active: true
    },
    {
        name: "Membership",
        active: true
    }
]*/

let formatCategoryFromApi = (category: string) => {
    if(!category || category == "") return "";
    return(category[0].toUpperCase() + category.slice(1,).toLowerCase())
}

interface widgetProps { walletData: TWalletData, updateWalletAttributes: Function, categoryFlag: boolean, updateCategoryFlag: Function, dropOptions:any}
export default function AddressAndCategory(props: widgetProps) {
    let { walletData, updateWalletAttributes, categoryFlag, updateCategoryFlag, dropOptions } = props;
    const updateCategory = (elements: Array<string>) => {updateWalletAttributes("Category", elements) };
    const changeWalletAddress = (event: any) => {updateWalletAttributes("WalletAddress", event.target.value)}

    return (
        <TwoSectionSplit>
            <SectionForm>
                <SectionTitle>Category <PurpleDot /></SectionTitle>
                <CustomDropMenu selected={formatCategoryFromApi(walletData.Category)} options={dropOptions.category} type="simple" updateSelected={updateCategory} dropFlag={categoryFlag} updateDropFlag={updateCategoryFlag} customDisabled={false} />
            </SectionForm>
            <SectionForm>
                <SectionTitle>Wallet Address <PurpleDot /></SectionTitle>
                <TextInputArea type="text" value={walletData.WalletAddress} onChange={changeWalletAddress}  withLogo={false} disabled={walletData.Validated} />
            </SectionForm>
            
        </TwoSectionSplit>
    );
}