import axios, { AxiosResponse } from 'axios';
import { app } from '../../config/config'
import { TEventData } from '../../components/eventsList/eventForm';
import EventList from '../../components/eventsList/eventList';
let BACKEND_URL = app.BACKEND_URL;

function formatDate(date: number) {
    let newDate = new Date(date);
    let day:any = newDate.getDate();
        day = day < 10? `0${day}`: day;
    let month:any = +newDate.getMonth() + 1;
        month = month < 10? `0${month}`: month;
    let year = newDate.getFullYear();
    return `${day}/${month}/${year}`;
}


let sampleData:any = [];

export interface TEventFormated {
    id: string,
    EventName: string,
    EventImage: string,
    Category: string,
    EventAddress: string
}
interface EventsState {
    registered_by: string,
    date: number,
    id_item: string,
    createdAt: number,
    event_name: string,
    desc: string
    main_image?: string
    status?: string|boolean,
    tipo?: string
}

export async function eventsRequest() {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/admin/events`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<{ data: Array<EventsState> }>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                const data = response.data.items.map((element: EventsState) => {
                    let fixedDate = formatDate(element.date);
                    return {
                        id: element.id_item,
                        EventName: element.event_name,
                        EventImage: element.main_image || "",
                        Description: element.desc,
                        Date: fixedDate,
                        Status: element.status === "launched" || false
                    }
                })
                resolve({ data })
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}

export async function localEventsRequest() {
    return new Promise<{ data: Array<any> }>((resolve, rejects) => {
        resolve(sampleData)
    })
}



function formatEventApiToLocal<TEventData>(data: any) {
    let convertedDate = data.date? formatDate(data.date) : "";
    if(convertedDate){
        let [day, month, year] = convertedDate.split("/");
        convertedDate = `${year}-${month}-${day}`
    }
    let eventFormated = {
        EventImage: data.main_image || "",
        EventName: data.event_name,
        Description: data.desc,
        Date: convertedDate,
        Status: data.status==="launched" || false,
        Type: data.tipo||""
    }
    return eventFormated
}

export function getEventInfo(eventId: string) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/admin/events/${eventId}`,
        method: "GET",
        withCredentials: true
    }

    return new Promise<TEventData>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                const data: TEventData = formatEventApiToLocal(response.data)
                resolve(data)
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}
/*export async function localGetEventInfo(eventId: string) {
    return new Promise<any>((resolve, rejects) => {
        let sampleEvent = sampleData.data.filter((event: any) => event.id === eventId)[0];
        resolve(sampleEvent)
    })
}*/



interface TUpdateEventInfo {
    eventId: string,
    data: {
        "event_name": string,
        "date": string|number,
        "desc": string,
    }
}
export function updateEventInfo({ eventId, data }: TUpdateEventInfo) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/admin/events/${eventId}`,
        method: "PUT",
        data: data,
        withCredentials: true
    }

    return new Promise<{ data: Array<EventsState> }>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                rejects("No data founded")
            })
    })
}


export interface TResponseCreate {
    event_id: string,
    result: string
}
interface TCreateEventInfo {
    data: {
        "type": string
        "event_name": string,
        "date": string|number,
        "desc": string
    }
}
export function createEventInfo({ data }: TCreateEventInfo) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/admin/events`,
        method: "POST",
        withCredentials: true,
        data: data
    }

    return new Promise<AxiosResponse<TResponseCreate>>((resolve, rejects) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                rejects(error)
            })
    })
}


interface TDeleteEvent {
    data: any
}
export function deleteEvent({ data }: TDeleteEvent) {
    
    const axiosOptions = {
        method: 'DELETE',
        withCredentials: true,
        url: `${BACKEND_URL}/api/admin/events/${data}`
    };

    return new Promise<AxiosResponse<any>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response)
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject(error)
            })
    })
}



interface TgetPresignedUrlProps {
    event_id: string,
    file_type: string,
    image_type: string,
    token_info?: any
}
interface TUploadEventFoto {
    id: string | undefined,
    file_type: string,
    image_type: string,
    files: any
}
export interface TuploadEventFotoResponse {
    result: 'success' | 'error',
    urlS3Image: string,
}

export function getPresignedUrl(props: TgetPresignedUrlProps) {
    const axiosOptions = {
        url: `${BACKEND_URL}/api/admin/events`,
        method: 'POST',
        data: {
            type: "uploadImage",
            ...props,
        },
        withCredentials: true,
    }
    return new Promise<AxiosResponse<TuploadEventFotoResponse>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject(error)
            })
    })
}

export function uploadImage(file: File | Blob, slug: string) {

    return new Promise<any>((resolve, reject) => {
        fetch(slug, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/octet-stream'
            },
            body: file
        })
            .then(data => {
                resolve({ result: 'success' })
            })
            .catch((error) => {
                console.log('userInfo error', error)
                reject(error)
            });
    })
}

export function uploadEventFoto(data: TUploadEventFoto) {
    const { id, file_type, image_type, files } = data
    const event_id = id;





    function updateInspeccion(toUpdate: any) {
        const axiosOptions = {
            method: 'POST',
            data: {
                type: "updateInspeccion",
                ...toUpdate,
            },
            withCredentials: true,
            url: `${BACKEND_URL}/api/admin/events`
        };

        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            axios(axiosOptions)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    // handle error
                    reject('No possible to update inspection')
                })
        })
    }
}

//     if (event_id && file_type) {

//         getPresignedUrl({ event_id, file_type, image_type })
//             .then((resp: any) => {
//                 console.log("Successfull getPresignedURL")
//                 uploadImage(files, resp.urlS3Image, file_type)
//                     .then(() => {
//                         console.log("Successfull uploadImage")
//                         updateInspeccion({ task_id: event_id, [`${image_type}`]: resp.urlS3Image })
//                             .then((respon) => {
//                                 console.log("Successfull updateInspeccion")
//                                 let chainParams = {
//                                     "getPresignedUrl": { event_id, file_type, image_type },
//                                     "uploadImage": [files, resp.urlS3Image, file_type],
//                                     "updateInspeccion": { task_id: event_id, [`${image_type}`]: resp.urlS3Image, }
//                                 }
//                                 //showIncConsole("Successfull uploadFoto cycle", chainParams)
//                                 return true;
//                             })
//                     })
//             })
//     }
// }
