import React, { useState, useEffect } from "react";
import { NewDateType } from './usedDatesContext2';
import {getOcupedDates} from '../services/reservations/booking'

const todayDateRef = new Date();
//const rawDates = getOcupedDates({date: `${todayDateRef.getDate()}/${todayDateRef.getMonth()+1}/${todayDateRef.getFullYear()}`});

const emptyDate: NewDateType = {
    Data: {
        StartDate: "",
        EndDate: "",
        Persons: 1,
        OneDay: false,
        Bungalows: 1,
        MaxDate: "",
        BetweenDays: []
    },
    updateData: () => { }
}

export const CurrentDateContex = React.createContext(emptyDate)

interface widgetProps { children: React.ReactNode };
export const CurrentDateProvider = (props: widgetProps) => {
    const [usedDates, setUsedDates] = useState(emptyDate.Data)

    const updateHandler = (data: any) => {
        setUsedDates({...data})
    }

    return (
        <CurrentDateContex.Provider value={{
            Data: usedDates,
            updateData: updateHandler
        }}>
            {props.children}
        </CurrentDateContex.Provider>
    );
}

export default CurrentDateContex;