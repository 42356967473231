import React, { useState, useEffect } from "react";
import { NewDateType } from './usedDatesContext2';
import {getOcupedDates} from '../services/reservations/booking'

const todayDateRef = new Date();
//const rawDates = getOcupedDates({date: `${todayDateRef.getDate()}/${todayDateRef.getMonth()+1}/${todayDateRef.getFullYear()}`});

interface TFilterDate{
    Data:{
        StartDate: string,
        EndDate: string
    },
    updateData: Function
}
const emptyDate: TFilterDate = {
    Data: {
        StartDate: "",
        EndDate: "",
    },
    updateData: () => { }
}

export const FilterDateContex = React.createContext(emptyDate)

interface widgetProps { children: React.ReactNode };
export const FilterDateProvider = (props: widgetProps) => {
    const [usedDates, setUsedDates] = useState(emptyDate.Data)

    const updateHandler = (data: any) => {
        setUsedDates({...data})
    }

    return (
        <FilterDateContex.Provider value={{
            Data: usedDates,
            updateData: updateHandler
        }}>
            {props.children}
        </FilterDateContex.Provider>
    );
}

export default FilterDateContex;