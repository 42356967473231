import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Arrow2 from '../../assets/icons/arrow2.png';
import CalendariesModal from "../modals/calendariesModal";
import FilterDateContex from "../../context/filterDateContext";

let shortNameMonths = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

const recursionSearchClass:any = (target:any, word:string, deep:number) => {
    if(deep <= 0 || !target) return false;
    if(target.className.includes(word))return true;
    else return recursionSearchClass(target.parentElement, word, deep-1)
}

interface TProps { modalCalendarFlag: boolean, setModalCalendarFlag: Function }
export default function SearchDate(props: TProps) {
    let { modalCalendarFlag, setModalCalendarFlag } = props;
    let filterDateContext = useContext(FilterDateContex);
    let {StartDate, EndDate} = filterDateContext.Data
    let [formatedDate, setFormatedDate] = useState("")
    
    const changeCalendarFlag = (event: any) => {
        let nameInClass = recursionSearchClass(event.target, "calendar-modal", 10);
        if (modalCalendarFlag && !nameInClass) setModalCalendarFlag(false)
        if(!modalCalendarFlag && !nameInClass) setModalCalendarFlag(true)
    }

    

    useEffect(()=>{
        let formatedDays = ""
        if(StartDate && StartDate.length){
            let [sday,smonth,syear] = StartDate.split("/");
            let stringMonth = shortNameMonths[Number(smonth)-1];
            formatedDays = `${stringMonth} ${sday}`
        }
        if(EndDate && EndDate.length){
            let [eday,emonth,year] = EndDate.split("/");
            let stringMonth = shortNameMonths[Number(emonth)-1];
            formatedDays = `${formatedDays} - ${stringMonth} ${eday}`
        }
        setFormatedDate(formatedDays)
    },[filterDateContext.Data])
    
    return (
        <SearchDateApp onClick={changeCalendarFlag}>
            <SearchInputText>{formatedDate}</SearchInputText>
            <SearchLogo src={Arrow2} alt="search" enabledTag={modalCalendarFlag} />
            {modalCalendarFlag && <CalendariesModal />}
        </SearchDateApp>
    );
}


const SearchDateApp = styled.div`
    position: relative;
    display: flex;
    height: 40px;
    width: 250px;
    cursor: pointer;
`

const SearchInputText = styled.div`
    flex: 1;
    font-family: "trade-gothic";
    border: 1px solid #F0EEDD;
    border-radius: 13px;
    padding: 11px;
    padding-right: 30px;
    font-size: 14px;
    color: #777777;
    text-align: left;
`

const SearchLogo = styled.img<{ enabledTag: boolean }>`
    position: absolute;
    width: 12px;
    object-fit: contain;
    right: 10px;
    top: 40%;
    transform: ${({ enabledTag }) => enabledTag ? "rotate(180deg)" : "default"};
`