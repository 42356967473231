import React from "react";
import PurpleWallet from '../../assets/icons/purpleWallet.png';
import PenGray from '../../assets/icons/penGray.png';
import { SectionForm, SectionTitle, WalletImageContainer, ImageInputType, EditButton, EmptyImageContainer, WalletImage } from './walletStylingForm'
import { onFileChange } from '../../services/collections/imageToFile'
import {TImageFiles} from '../walletsList/walletForm'

interface widgetProps { walletData: any, updateWalletAttributes: Function, updateImageFiles: Function, imageFiles:TImageFiles }

export default function Wallet(props: widgetProps) {

    let { walletData, updateWalletAttributes, updateImageFiles, imageFiles } = props;

    let updateWalletImage = (event: any) => {
        let walletImageUrl = URL.createObjectURL(event.target.files[0])
        updateWalletAttributes("WalletImage", walletImageUrl)

        onFileChange(event).then((response: any) => {
            let temporalInfo: TImageFiles = { ...imageFiles }
            temporalInfo.WalletImageData.file = response[0].file;
            temporalInfo.WalletImageData.url = walletImageUrl;
            updateImageFiles(temporalInfo)
        }).catch((error) => {
            console.log("error: " + error)
        })
    }
    let clickWallet = () => { document.getElementById("WalletImageFile")?.click() }


    let emptyImage = (
        <EmptyImageContainer onClick={clickWallet} clickable={true}>
            <img src={PurpleWallet} alt="EmptyImage" width="128px" height="128px" />
        </EmptyImageContainer>
    )
    let walletImage = <WalletImage src={walletData.WalletImage} alt="WalletImage" />;

    return (
        <SectionForm>
            <SectionTitle>Wallet image</SectionTitle>
            <WalletImageContainer>
                {walletData.WalletImage ? walletImage : emptyImage}
                {!walletData.WalletImage ? "" : (
                    <EditButton onClick={clickWallet} disabled={false} state={false}>
                        Edit wallet image
                        <img src={PenGray} alt="edit" />
                    </EditButton>
                )}
                <ImageInputType type="file" id="WalletImageFile" name="WalletImageFile" onChange={updateWalletImage} />
            </WalletImageContainer>
        </SectionForm>
    );
}