import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setImagesInfo, selectImagesInfo, imageData, imageInfo } from '../../services/collections/imageCollectionSlice';
import EditableField from "./editableField";

let emptyImage: TMappedImages = {};
/*let emptyImageCollections: imageData = {
    logoImage: undefined,
    bannerImage: undefined,
    additionalImages: [],
    tokenImages: []
}*/

interface TMappedInfo { id: string, url: string, selected: boolean, title?: string, description?: string, quantity?: number, additionalDescription?: string }
interface TMappedImages { [id: string]: TMappedInfo }
interface widgetProps { images: Array<string>, updateImages: Function, updateModalState: Function, section: string }
export default function ImageOptionsModal(props: widgetProps) {
    let dispatch = useAppDispatch();
    let imagesCollectionContext = useAppSelector(selectImagesInfo);
    let { bannerImage, logoImage, additionalImages, tokenImages } = imagesCollectionContext.data;

    let [imageObjectList, setImageObjectList] = useState(emptyImage);
    let [maximumIndex, setMaximumIndex] = useState(0);
    let [newImagesCollection, setNewImagesCollection] = useState({ additionalImages, tokenImages, bannerImage, logoImage });

    useEffect(() => {
        let mapedImages: TMappedImages = {};
        props.images.map((image: any, index) => {
            let imageUrl = (typeof image === 'object') ? image.url : image;
            let idImage = (typeof image === 'object') ? image.id : index
            mapedImages[String(index)] = {
                id: idImage,
                url: imageUrl,
                selected: false
            }

            if (props.section === "token") {
                let tokenInfo = tokenImages.filter((tokenImage: any) => tokenImage.url == image)[0].info;
                //let tokenInfo = tokenImages[index].info;
                mapedImages[String(index)].title = tokenInfo ? tokenInfo.title : "";
                mapedImages[String(index)].quantity = tokenInfo ? tokenInfo.quantity : 0;
                mapedImages[String(index)].description = tokenInfo ? tokenInfo.description : "";
                mapedImages[String(index)].additionalDescription = tokenInfo ? tokenInfo.additionalDescription : "";
            }
        })

        setImageObjectList(mapedImages);
        setMaximumIndex(props.images.length - 1);
    }, [])



    const saveCollection = () => {
        if (props.section !== "token") {
            let newImages = Object.keys(imageObjectList).map(x => imageObjectList[x].url);
            dispatch(setImagesInfo(newImagesCollection))
            props.updateImages(newImages)
            props.updateModalState()
        }
        else {

            let newImages = Object.keys(imageObjectList).map(x => imageObjectList[x].url);


            //let temporalImageCollection = {...newImagesCollection}
            /*let newApiImagesInfo = Object.keys(imageObjectList).map((element, index) => {
                return {
                    title: imageObjectList[element].title,
                    description: imageObjectList[element].description,
                    quantity: imageObjectList[element].quantity,
                    additionalDescription: imageObjectList[element].additionalDescription
                }
            })*/
            //temporalImageCollection.tokenImages.apiImagesInfo = newApiImagesInfo;   


            let temporalImageCollection = { ...newImagesCollection }

            let newApiImagesInfo = Object.keys(imageObjectList).map((element, index) => {
                return {
                    title: imageObjectList[element].title,
                    description: imageObjectList[element].description,
                    quantity: imageObjectList[element].quantity,
                    additionalDescription: imageObjectList[element].additionalDescription,
                    id: imageObjectList[element].id,
                    selected: imageObjectList[element].selected,
                    url: imageObjectList[element].url
                }
            })

            let temporalTokenImages = [...temporalImageCollection.tokenImages];
            temporalTokenImages = temporalTokenImages.map((element, index) => {
                let foundedImage = newApiImagesInfo.filter((imageObject, index) => imageObject.url === element.url)[0];
                let newElement = { ...element }
                if (foundedImage) {

                    let newInfo = {
                        ...element.info,
                        additionalDescription: foundedImage.additionalDescription,
                        quantity: foundedImage.quantity,
                        title: foundedImage.title
                    };
                    newElement = { ...element, info: newInfo }
                }
                return newElement
            })

            temporalImageCollection.tokenImages = temporalTokenImages;

            setNewImagesCollection(temporalImageCollection)
            dispatch(setImagesInfo(temporalImageCollection))
            props.updateImages(newImages)
            props.updateModalState()
        }
    }



    const removeImage = () => {
        let localAdditionalImages = [...newImagesCollection.additionalImages];
        let localTokenImages = [...newImagesCollection.tokenImages];

        let indexToDelete = Object.keys(imageObjectList).filter(key => imageObjectList[key].selected)[0];
        if (indexToDelete) {
            let imageUrl = imageObjectList[indexToDelete].url;

            if (props.section === "additional") {
                let localIndex = localAdditionalImages.findIndex((objectImage) => objectImage.url === imageUrl)
                let currentImageState = localAdditionalImages[localIndex].state;
                let isLocalImage = (currentImageState === "ToAdd")
                if (isLocalImage) {
                    setNewImagesCollection((prevState) => {
                        let newState: imageData = { ...prevState };
                        let newAdditionalImages = [...newState.additionalImages];
                        newAdditionalImages.splice(localIndex, 1);
                        newState.additionalImages = newAdditionalImages;
                        return newState;
                    })
                }
                else {
                    setNewImagesCollection((prevState) => {
                        let newState: imageData = { ...prevState };

                        let newAdditionalImages = [...newState.additionalImages];
                        let newAdditionalImage = {
                            id: newAdditionalImages[Number(localIndex)].id,
                            file: newAdditionalImages[Number(localIndex)].file,
                            url: newAdditionalImages[Number(localIndex)].url,
                            state: "ToDelete",
                            info: { ...newAdditionalImages[Number(localIndex)].info }
                        }
                        newAdditionalImages[Number(localIndex)] = newAdditionalImage;
                        newState.additionalImages = newAdditionalImages;
                        return newState;
                    })
                }
            }

            if (props.section === "token") {
                let localIndex = localTokenImages.findIndex((objectImage) => objectImage.url === imageUrl)
                let currentImageState = localTokenImages[localIndex].state;
                let isLocalImage = (currentImageState === "ToAdd")
                if (isLocalImage) {
                    setNewImagesCollection((prevState) => {
                        let newState: imageData = { ...prevState };
                        let newTokenImages = [...newState.tokenImages];
                        newTokenImages.splice(localIndex, 1)
                        newState.tokenImages = newTokenImages;
                        return newState;
                    })
                }
                else {
                    setNewImagesCollection((prevState) => {
                        let newState: imageData = { ...prevState };
                        let newTokenImages = [...newState.tokenImages];
                        let newTokenImage = {
                            id: newTokenImages[Number(localIndex)].id,
                            file: newTokenImages[Number(localIndex)].file,
                            url: newTokenImages[Number(localIndex)].url,
                            state: "ToDelete",
                            info: { ...newTokenImages[Number(localIndex)].info }
                        }
                        newTokenImages[Number(localIndex)] = newTokenImage;
                        newState.tokenImages = newTokenImages;
                        return newState;
                    })
                }
            }

            setImageObjectList(prevState => {
                let newState = { ...prevState }
                delete newState[indexToDelete];
                return newState
            })
        }

    }



    const updateSelected = (event: any) => {
        const targetClass = event.target.className.includes("imageBox-") ? event.target.className :
            event.target.parentNode.className.includes("imageBox-") ? event.target.parentNode.className : event.target.parentNode.parentNode.className;
        const key = targetClass.split("imageBox-").pop();

        setImageObjectList(prevState => {
            let newState: TMappedImages = {};
            Object.keys(prevState).map(x => newState[x] = { ...prevState[x], selected: false });
            newState[key].selected = true;
            return newState;
        })
    }



    const addImage = () => { document.getElementById("ExtraImageFile")?.click() }
    const addAdditionalImage = (event: any) => {
        let imageUrl = URL.createObjectURL(event.target.files[0]);

        if (props.section === "additional") {
            //let isInToAdd = additionalImages.toAdd.includes(imageUrl);
            let isInToAdd = newImagesCollection.additionalImages.filter((element: imageInfo) => element.url === imageUrl).length;;
            if (!isInToAdd) {
                setNewImagesCollection((prevState: imageData) => {
                    let newState: imageData = { ...prevState };
                    let newAdditionalImages = [...newState.additionalImages];
                    let newImageFile = {
                        id: String(maximumIndex + 1),
                        url: imageUrl,
                        file: event.target.files[0],
                        state: "ToAdd" //can be "Original" "ToAdd" "ToEdit" "ToDelete"
                    }
                    newAdditionalImages.push(newImageFile)
                    newState.additionalImages = newAdditionalImages;
                    return newState;
                })
            }
        }

        if (props.section === "token") {
            //let isInToAdd = tokenImages.toAdd.includes(imageUrl);
            let isInToAdd = newImagesCollection.tokenImages.filter((element: imageInfo) => element.url === imageUrl).length;;
            if (!isInToAdd) {
                setNewImagesCollection((prevState: imageData) => {
                    let newState: imageData = { ...prevState };
                    let newTokenImages = [...newState.tokenImages];
                    let newImageFile = {
                        id: String(maximumIndex + 1),
                        url: imageUrl,
                        file: event.target.files[0],
                        info: {
                            title: "",
                            description: "",
                            quantity: 0,
                            additionalDescription: ""
                        },
                        state: "ToAdd" //can be "Original" "ToAdd" "ToEdit" "ToDelete"
                    }
                    newTokenImages.push(newImageFile)
                    newState.tokenImages = newTokenImages;
                    return newState;
                })

            }
        }

        setImageObjectList(prevState => {
            let newState: TMappedImages = { ...prevState };
            newState[String(maximumIndex + 1)] = {
                id: String(maximumIndex + 1),
                url: imageUrl,
                selected: false,
                title: "",
                description: "",
                additionalDescription: "",
                quantity: 0
            }
            return newState;
        })
        setMaximumIndex(prevState => prevState + 1);
    }



    let resetSelection = (event: any) => {
        if (event.target.className.includes("MainBoxContainerModal") || event.target.parentNode.className.includes("MainBoxContainerModal")) {
            setImageObjectList(prevState => {
                let newState: TMappedImages = { ...prevState };
                for (let i in newState) newState[i].selected = false;
                return newState;
            })
        }
    }



    let updateTokenValues = (value: string | number, keyId: string, parameter: string) => {
        setImageObjectList((prevState: any) => {
            let newState: any = { ...prevState };
            newState[keyId][parameter] = value;
            return newState;
        })

        setNewImagesCollection((prevState: imageData) => {
            let newState: any = { ...prevState };
            let newTokenImages = [...newState.tokenImages];

            let newKeyId = newTokenImages.findIndex(element => element.url === imageObjectList[keyId].url)
            let updatedState = newTokenImages[Number(newKeyId)].state === "Original" ? "ToEdit" : newTokenImages[Number(newKeyId)].state

            let newTokenImage = {
                id: newTokenImages[Number(newKeyId)].id,
                file: newTokenImages[Number(newKeyId)].file,
                url: newTokenImages[Number(newKeyId)].url,
                state: updatedState,
                info: { ...newTokenImages[Number(newKeyId)].info }
            }
            newTokenImage.info[parameter] = value
            newTokenImages[Number(newKeyId)] = newTokenImage

            newState.tokenImages = newTokenImages;
            return newState;
        })
    }
    let updateTitle = (value: string, keyId: string) => { updateTokenValues(value, keyId, "title") }
    let updateAmount = (value: number, keyId: string) => { updateTokenValues(value, keyId, "quantity") }
    let updateDescription = (value: string, keyId: string) => { updateTokenValues(value, keyId, "description") }
    let updateAdditionalDescription = (value: string, keyId: string) => { updateTokenValues(value, keyId, "additionalDescription") }



    let imageBlocks = Object.keys(imageObjectList).map((key: string, index) => {
        return (
            <CardImageBox key={key} >
                <CardImage selected={imageObjectList[key].selected} onClick={updateSelected} className={`imageBox-${key}`}>
                    <ImageContainer src={imageObjectList[key].url} selected={imageObjectList[key].selected} alt={`imageBox-${key}`}></ImageContainer>
                    {props.section === "token" &&
                        <ImageInfo className={`imageBox-${key}`}>
                            <EditableField keyId={key} name="Title" type="text" enabled={imageObjectList[key].selected} data={imageObjectList[key].title} updateData={updateTitle} />
                            <EditableField keyId={key} name="Amount" type="number" enabled={imageObjectList[key].selected} data={imageObjectList[key].quantity} updateData={updateAmount} />
                            <EditableField keyId={key} name="Description" type="text" enabled={imageObjectList[key].selected} data={imageObjectList[key].description} updateData={updateDescription} />
                            <EditableField keyId={key} name="Additional Description" type="text" enabled={imageObjectList[key].selected} data={imageObjectList[key].additionalDescription} updateData={updateAdditionalDescription} />
                        </ImageInfo>
                    }
                </CardImage>
            </CardImageBox>
        )
    })

    return (
        <React.Fragment>
            <MainModal onClick={() => { props.updateModalState() }} />
            <MainBox onClick={resetSelection} className="MainBoxContainerModal">
                <ContentBox className="MainBoxContainerModal">
                    {imageBlocks}
                    <ImageInputType type="file" id="ExtraImageFile" name="ExtraImageFile" onChange={addAdditionalImage} />
                </ContentBox>

                <ButtonsBar>
                    <CButton backColor="#84004C" fontColor="#FFFFFF" borderColor="#84004C" onClick={addImage}>Add New</CButton>
                    <CButton backColor="#84004C" fontColor="#FFFFFF" borderColor="#84004C" onClick={saveCollection}>Save</CButton>
                    <CButton onClick={removeImage}>Delete</CButton>
                    <CButton onClick={() => { props.updateModalState() }}>Cancel</CButton>
                </ButtonsBar>
            </MainBox>
        </React.Fragment>
    );
}

const MainModal = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    height: 100vh;
    background: #000000b9;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MainBox = styled.div`
    box-sizing: border-box;
    position: fixed;
    width: 60%;
    height: 80vh;
    background: #FFFFFF;
    z-index: 1000;
    right: 21%;
    top: 10vh;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const ContentBox = styled.div`
    box-sizing: border-box;
    width: 100%;
    //height: 100%;
    flex: 1;
    border: 1px solid #AAAAAA;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: flex-start;
    gap: 20px;
    border-radius: 10px;
`

const CardImageBox = styled.div`
    //height: 150px;
    //max-width: 190px;
    flex: 1;
    flex-basis: 190px;
    
`

const CardImage = styled.div<{ selected: boolean }>`
    //width: 180px;
    box-sizing: border-box;
    margin: auto;
    width: 228px;
    border-radius: 10px;
    
    cursor: pointer;
    box-shadow: ${({ selected }) => selected ? "2px 2px 4px #000000,rgba(17, 17, 26, 0.384) 0px 4px 16px, rgba(17, 17, 26, 0.514) 0px 8px 24px, rgba(17, 17, 26, 0.425) 0px 16px 56px" : "2px 2px 4px #000000"};
    transition: all 0.1s linear;
    left: 0px;

    &:hover{
        box-shadow: ${({ selected }) => selected ? "2px 2px 4px #000000,rgba(17, 17, 26, 0.384) 0px 4px 16px, rgba(17, 17, 26, 0.514) 0px 8px 24px, rgba(17, 17, 26, 0.425) 0px 16px 56px" : "2px 2px 4px #000000,rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px"};
        width: 232px;
        //height: 204px;
        left: -2px;

        img{
            height: 154px;
        }
    }
`

const ImageContainer = styled.img<{ selected: boolean }>`
    position: relative;
    display: block;
    object-fit: contain;
    //width: 150px;
    width: 100%;
    height: 150px;
    transition: all 0.1s linear;
    border-radius: 10px;
`

const ImageInfo = styled.div`
    padding: 10px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 2px;
`


const ButtonsBar = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
`

const CButton = styled.button<{ backColor?: string, fontColor?: string, borderColor?: string }>`
    background: ${({ backColor }) => backColor || "#FFFFFF"};
    color: ${({ fontColor }) => fontColor || "#830F5B"};
    cursor: pointer;
    
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 0px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ borderColor }) => borderColor || "#830F5B"};
    border-radius: 10px;
    flex: 1;
    max-width: 100px;
    
    &:hover{
        background: ${({ backColor }) => backColor ? backColor + "F0" : "#fff2fa"};
    }
`

const ImageInputType = styled.input`
    display: none;
`