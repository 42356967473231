import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { BrowserRouter } from "react-router-dom"
import App from './app/App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import './font-family.css'
import { CurrentDateProvider } from './context/currentDateContext'
import { UsedDateProvider } from './context/usedDatesContext2'
import ViewportProvider from './context/viewportContext'

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ViewportProvider>
          <CurrentDateProvider>
            <UsedDateProvider>
              <App />
            </UsedDateProvider>
          </CurrentDateProvider>
        </ViewportProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
