import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { eventsRequest, localEventsRequest } from './eventsReq';

const initialEventsState: any = {
    data: [],
    status: 'idle',
    error: null
};

export const fetchEvents = createAsyncThunk("events/fetchEvents", async () => {
    try {
        const response = await eventsRequest(); // for an API requests
        //const response = await localEventsRequest(); // for local data tests
        return [...response.data]
    } catch (err: any) {
        return err.message
    }
})

export const eventsSlice = createSlice({
    name: "events",
    initialState: initialEventsState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = 'fullfiled'
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
})

export const getApiEvents = (state: any) => state.events.data;
export const getApiEventsStatus = (state: any) => state.events.status;
export const getApiEventsErrors = (state: any) => state.events.error;

export default eventsSlice.reducer;