import React, { useState } from "react";
import CustomDropMenu from '../collection/customDropMenu'
import { SectionForm, SectionTitle, TextInputArea, TwoSectionSplit, PurpleDot, PriceCurrency } from './stylingForm'
import { TResponseGet, TResponseCreate, TResponseConfig } from '../../services/collections/collectionReq'

/*const currenciesOptions = [
    {
        name: "Credit",
        active: true
    },
    {
        name: "Debit",
        active: true
    },
    {
        name: "ETH",
        active: true
    },
    {
        name: "Sol",
        active: true
    },
    {
        name: "VivaCoin",
        active: false
    },
    {
        name: "VineCoin",
        active: false
    }
]*/

interface widgetProps { collectionData: any, updateCollectionAttributes: Function, currencyFlag:boolean, updateCurrencyFlag:Function, normalizeNumber:Function, dropOptions:any }
export default function CurrencyAndPrice(props: widgetProps) {
    let { collectionData, updateCollectionAttributes, currencyFlag, updateCurrencyFlag, normalizeNumber, dropOptions } = props;
    const updateCurrencies = (elements: Array<string>) => {updateCollectionAttributes("Currencies", elements) };
    const changePrice = (event: any) => { updateCollectionAttributes("Price", event.target.value) }
    return (
        <TwoSectionSplit>
            <SectionForm>
                <SectionTitle>Currencies <PurpleDot /></SectionTitle>
                <CustomDropMenu selected={collectionData.Currencies} options={dropOptions.currencies} type="multi" updateSelected={updateCurrencies} dropFlag={currencyFlag} updateDropFlag={updateCurrencyFlag} customDisabled={collectionData.Launched} />
            </SectionForm>

            <SectionForm>
                <SectionTitle>Price <PurpleDot /></SectionTitle>
                <TextInputArea type="number" value={collectionData.Price} onChange={changePrice} withLogo={false} onBlur={()=>{normalizeNumber()}} disabled={collectionData.Launched} />
                <PriceCurrency>ETH</PriceCurrency>
            </SectionForm>
        </TwoSectionSplit>
    );
}