import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import ReactDOM from 'react-dom'

interface BackdropType {
    okClick: MouseEventHandler
}

export default function ModalReserved(props: BackdropType) {
    const rootBackDrop = document.getElementById('modals');
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <ModalContainer>
                    <BoxMain>
                        <Title>Reserved Successfully</Title>
                        <Message>We are waiting for you the day of your visit.</Message>
                        <OkButton onClick={props.okClick}>OK</OkButton>
                    </BoxMain>
                </ModalContainer>,
            rootBackDrop as Element)}
        </React.Fragment>
    );
}

const ModalContainer = styled.div`
    position: fixed;    
    z-index: 1001;
    left: calc(100vw/2 - 168px);
    top: calc(100vh/2 - 110px)
`
const BoxMain = styled.div`
    border-radius: 10px;
    background: #F0EEDD;
    text-align: center;
    font-family: 'trade-gothic';
    padding: 32px;
`
const Title = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
`
const Message = styled.p`
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    color: #979696;
    margin: 15px 0px;
`
const OkButton = styled.button`
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    background: #84004C;
    color: #FFFFFF;
    text-transform: uppercase;
    margin: 10px 0px;
    cursor: pointer
`