import React, {useState} from "react";
import { SectionForm, SectionTitle, DescriptionText } from './stylingForm'

interface widgetProps { collectionData: any, updateCollectionAttributes: Function }
export default function Description( props: widgetProps ) {
    let { collectionData, updateCollectionAttributes } = props;
    let changeDescription = (event: any) => { updateCollectionAttributes("Description", event.target.value) }
    return(
        <SectionForm>
                <SectionTitle>Description</SectionTitle>
                <DescriptionText value={collectionData.Description} onChange={changeDescription} rows={10} disabled={false/* collectionData.Launched */} />
            </SectionForm>
    );
}