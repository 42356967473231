import React, {useState} from "react";
import styled from 'styled-components';
import HelpImage from '../../assets/icons/helpIcon.png'
import './InfoToolTip.css'

interface widgetProps { message: string }
export default function InfoToolTip( props: widgetProps ) {
    return(
        <div className="infoTooltipApp">
            <img src={HelpImage} className="helpIcon"/>
            <div className="infoToolTipMessage">
                {props.message}
            </div>
        </div>
    );
}

