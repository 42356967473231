import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCollection, updateCollection, createCollection, getDropmenuOptions } from "../../services/collections/collectionReq";
import { TResponseGet, TResponseCreate, TResponseConfig } from '../../services/collections/collectionReq'
import { walletsRequest, TWalletFormated } from '../../services/wallets/walletsReq'
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setImagesInfo, selectImagesInfo, imageData, imageInfo, imageSpecs } from '../../services/collections/imageCollectionSlice';
import { uploadFoto } from '../../services/collections/imageToFile'
import { AxiosResponse } from 'axios';

import SwitchButton from '../switchButton/switchButton';
import Banner from '../FormElements/bannerImage'
import ButtonsArea from '../FormElements/buttonsArea'
import CategoryAndWallet from '../FormElements/categoryAndWallet'
import CollectionName from '../FormElements/collectionName'
import CurrencyAndPrice from '../FormElements/currencyAndPrice'
import Description from '../FormElements/description'
import ExtraImages from '../FormElements/extraImages'
import Logo from '../FormElements/logoImage'
import Royalty from '../FormElements/royalty'


interface widgetProps {
    type: string
    handleSave: Function,
    handleDelete?: Function
};

interface TCollectionData {
    id?: string,
    BannerImage: string,
    LogoImage: string,
    AditionalImages: Array<string> | String,
    TokenImages: Array<string> | String,
    Description: string,
    CollectionName: string,
    Currencies: Array<string>,
    Category: string,
    RoyaltyWallet: string,
    Price: string,
    CreatorWallet: string,
    RoyaltyPercentage: string,
    Launched: boolean
}

export interface TDropOptions{
    name: string,
    value: string,
    active: boolean,
    category?: string
}

const emptyCollection: TCollectionData = {
    BannerImage: "",
    LogoImage: "",
    AditionalImages: [],
    TokenImages: [],
    Description: "",
    CollectionName: "",
    Currencies: [],
    Category: "",
    RoyaltyWallet: "",
    Price: "",
    CreatorWallet: "",
    RoyaltyPercentage: "",
    Launched: false
}

const requiredFields: Array<string> = ["BannerImage", "LogoImage", "TokenImages", "CollectionName", "Currencies", "Category", "RoyaltyWallet", "Price", "CreatorWallet", "RoyaltyPercentage"]
const errorMesages: Object = {
    "BannerImage": "It is necessary to have a Banner Image",
    "LogoImage": "It is necessary to have a Logo Image",
    "TokenImages": "It is necessary to have at least one Token Image",
    "CollectionName": "Please fill the Collection Name",
    "Currencies": "Add at least one Currency",
    "Category": "Select a Category for the collection",
    "RoyaltyWallet": "Add a Royalty Wallet Address",
    "Price": "Please add Price for the collection",
    "CreatorWallet": "Select a Creator Wallet option",
    "RoyaltyPercentage": "Add a Royalty percentage"
}

const checkRequired = (collection: TCollectionData) => {
    let activateAlert = { state: false, element: "" };
    let newCollection: any = { ...collection }
    for (let key in collection) {
        if (requiredFields.includes(key)) {
            if (!newCollection[key] || !newCollection[key].length) {
                activateAlert.state = true;
                activateAlert.element = key;
                break;

            }
        }
    }
    return activateAlert;
}

const emptyOptions = {
    "currencies": ["ETH"],
    "category": ["Wine"],
    "creator_wallet": ["iReserve - A54B"],
    "currencies_status":["enabled"],
    "category_status":["enabled"],
    "creator_wallet_status": ["enabled"]
}


export default function CollectionForm(props: widgetProps) {
    let dispatch = useAppDispatch();
    let imagesCollectionContext = useAppSelector(selectImagesInfo);
    let { bannerImage, logoImage, additionalImages, tokenImages } = imagesCollectionContext.data

    let [collectionData, setCollectionData] = useState(emptyCollection);
    let [alert, setAlert] = useState({ state: false, element: "" });
    let [dropMenuOption, setDropMenuOptions] = useState<TResponseConfig>(emptyOptions);
    let navigate = useNavigate();

    let [currencyFlag, setCurrencyFlag] = useState(false);
    let updateCurrencyFlag = () => setCurrencyFlag(prevState => !prevState)
    let [categoryFlag, setCategoryFlag] = useState(false)
    let updateCategoryFlag = () => setCategoryFlag(prevState => !prevState)
    let [cWalletFlag, setCWalletFlag] = useState(false)
    let updateCWalletFlag = () => setCWalletFlag(prevState => !prevState)
    let [cRoyaltyFlag, setCRoyaltyFlag] = useState(false)
    let updateCRoyaltyFlag = () => setCRoyaltyFlag(prevState => !prevState)


    let { id } = useParams()

    useEffect(() => {

        let cleanImagesData = {
            bannerImage: undefined,
            logoImage: undefined,
            additionalImages: [],
            tokenImages: []
        }
        dispatch(setImagesInfo(cleanImagesData));

        setAlert({ state: false, element: "" })
        if ((props.type === "edit") && id) {
            /*let collectionApi = getLocalCollection({ collectionId: id });
            let newCollection = {
                BannerImage: collectionApi.BannerImage,
                LogoImage: collectionApi.LogoImage,
                AditionalImages: collectionApi.AditionalImages,
                TokenImages: collectionApi.TokenImages,
                Description: collectionApi.Description,
                CollectionName: collectionApi.CollectionName,
                Currencies: collectionApi.Currencies,
                Category: collectionApi.Category,
                RoyaltyWallet: collectionApi.RoyaltyWallet,
                Price: collectionApi.Price,
                CreatorWallet: collectionApi.CreatorWallet,
                RoyaltyPercentage: collectionApi.RoyaltyPercentage,
                Launched: collectionApi.Launched
            }
            setCollectionData(newCollection);*/

            getCollection({ collectionId: id }).then((response: TResponseGet) => {

                //==== asign only images
                let additionalImagesValues = Array.isArray(response.data["additional_image"]) ? response.data["additional_image"] :
                    response.data["additional_image"] ? [response.data["additional_image"]] : [];
                let currencies = Array.isArray(response.data["currencies"]) ? response.data["currencies"] :
                    !response.data["currencies"] ? [] :
                        response.data["currencies"].includes(",") ? response.data["currencies"].split(",") : [response.data["currencies"]]

                let tokeImagesValues = Array.isArray(response.data["token_image"]) ? response.data["token_image"] :
                    response.data["token_image"] ? [response.data["token_image"]] : [];
                let tokenImagesOnly = tokeImagesValues.map(element => (typeof element === "object") ? element.url||"" : element)


                //===== asign aditional info for imageObject list
                let additionalImagesInfo = additionalImagesValues.map((element, index) => {
                    return {
                        id: String(index),
                        url: element,
                        file: undefined,
                        state: "Original"
                    }
                })

                let tokeImagesInfo = tokeImagesValues.map((element, index) => {
                    let tokenUrl = element.url? element.url: typeof element === 'string'? element: ""
                    return {
                        id: element.id||String(index),
                        url: tokenUrl,
                        file: undefined,
                        info: {
                            title: element.title||"",
                            description: element.desc||"",
                            additionalDescription: element["additional_desc"]||"",
                            quantity: element.amount||0,
                            fullfilled: element.fullfilled||false,
                        },
                        state: "Original" //can be "Original" "ToAdd" "ToEdit" "ToDelete"
                    }
                })



                let newCollection: TCollectionData = {
                    BannerImage: response.data["banner_image"],
                    LogoImage: response.data["logo_image"],
                    //AditionalImages: Array.isArray(response.data["additional_image"]) ? response.data["additional_image"] : [response.data["additional_image"]],
                    //TokenImages: Array.isArray(response.data["token_image"]) ? response.data["token_image"] : [response.data["token_image"]],
                    AditionalImages: additionalImagesValues,
                    TokenImages: tokenImagesOnly,
                    Description: response.data["description"],
                    CollectionName: response.data["collection_name"],
                    Currencies: currencies,
                    Category: response.data["category"],
                    RoyaltyWallet: response.data["royalty_wallet_address"],
                    Price: String(response.data["price"]),
                    CreatorWallet: response.data["creator_wallet"],
                    RoyaltyPercentage: String(response.data["royalty_percentage"]),
                    Launched: response.data["status"] === "launched" ? true : false
                }
                setCollectionData(newCollection);
                //console.log({newCollection})

                let initialImagesData = {
                    bannerImage: bannerImage,
                    logoImage: logoImage,
                    additionalImages: additionalImagesInfo,
                    tokenImages: tokeImagesInfo
                }
                dispatch(setImagesInfo(initialImagesData));



            }).catch((error) => {
                console.log("error: " + error)
            })
        }

        

        getDropmenuOptions().then((response: any) => {
            let dropMenuOptions:TResponseConfig = {
                currencies: [],
                category:[],
                creator_wallet:[]
            }
            let newResponse = response.currencies? response: emptyOptions;
            
            dropMenuOptions.currencies = newResponse.currencies.map((currency: string, index: number) => { return { name: currency, active: newResponse["currencies_status"][index] === "enabled" } });
            dropMenuOptions.category = newResponse.category.map((currency: string, index: number) => { return { name: currency, active: newResponse["category_status"][index] === "enabled" } });
            //"creator_wallet": newResponse["creator_wallet"].map((currency: string, index: number) => { return { name: currency, active: newResponse["creator_wallet_status"][index] === "enabled" } })
           
            walletsRequest().then((responseWallets:any) => {
                dropMenuOptions.creator_wallet = responseWallets.data.map((wallet:TWalletFormated)=> {
                    let walletInCollectionForm:TDropOptions = {
                        //name: `${wallet.WalletName} - ${shortedAddress}`,
                        name: `${wallet.WalletName} - ${wallet.WalletAddress}`,
                        value: `${wallet.WalletName} - ${wallet.WalletAddress}`,
                        //value: wallet.WalletAddress,
                        active: true,
                        category: wallet.Category
                    } 
                    return walletInCollectionForm;
                })
                setDropMenuOptions(dropMenuOptions);
            })    
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const updateCollectionAttributes = (property: string, value: string | Array<string> | boolean) => {
        //console.log("Updating collections data")
        //console.log(collectionData)
        //console.log("imageCollections data")
        //console.log(imagesCollectionContext.data)
        
        setCollectionData((prevState: TCollectionData) => {
            let newState: TCollectionData | any = { ...prevState };
            newState[property] = value;
            return newState;
        })
    }

    // Normalize Price and Royalty percentage sections
    const normalizeNumber = () => {
        setCollectionData((prevState: TCollectionData) => {
            let newState: TCollectionData | any = { ...prevState };
            newState["Price"] = newState["Price"].replace(/^-*/g, '');
            newState["RoyaltyPercentage"] = newState["RoyaltyPercentage"].replace(/^-*/g, '');
            return newState;
        })
    }

    // Reset dropmenu state when clicking outside
    const resetDropStates = (event: any) => {
        let clickedCurrentInputOptions = event.target.classList.value.match(/(?<=option-)\d+|(?<=selection-)\d+/);
        if (!clickedCurrentInputOptions) {
            if (currencyFlag || categoryFlag || cWalletFlag || cRoyaltyFlag) {
                setCWalletFlag(false);
                setCurrencyFlag(false);
                setCategoryFlag(false);
                setCRoyaltyFlag(false);
            }
        }
    }

    // Changing Draft switch when clicked
    const changeDraftState = () => {
        let activateAlert = checkRequired(collectionData);
        setAlert(activateAlert);
        if (!collectionData.Launched && !activateAlert.state) updateCollectionAttributes("Launched", true)
    }


    //===== Buttons
    const handleSave = () => {
        let activateAlert = checkRequired(collectionData);
        setAlert(activateAlert);
        if (!activateAlert.state) {
            let saveParameters = {
                idCollection: id,
                collectionData: collectionData,
                bannerImage: bannerImage,
                logoImage: logoImage,
                additionalImages: additionalImages,
                tokenImages: tokenImages
            }
            props.handleSave(saveParameters)
        }
    }

    const handleDelete = () => {
        if (props.handleDelete) props.handleDelete(id)
    }

    const handleCancel = () => { navigate("/collections") };



    return (
        <FormCollection onClick={resetDropStates}>

            <Title><h2>{props.type === "edit" ? "EDIT" : 'CREATE'} COLLECTION</h2></Title>
            <RequiredAdd> <p><PurpleDot /> Required fields </p></RequiredAdd>
            <Banner collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} />
            <Logo collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} />
            <ExtraImages collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} />
            <Description collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} />
            <CollectionName collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} />
            <CurrencyAndPrice collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} currencyFlag={currencyFlag} updateCurrencyFlag={updateCurrencyFlag} normalizeNumber={normalizeNumber} dropOptions={dropMenuOption || []} />
            <CategoryAndWallet collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} categoryFlag={categoryFlag} updateCategoryFlag={updateCategoryFlag} cWalletFlag={cWalletFlag} updateCWalletFlag={updateCWalletFlag} dropOptions={dropMenuOption || []} />
            {/* <Royalty collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} normalizeNumber={normalizeNumber} /> */}
            <Royalty collectionData={collectionData} updateCollectionAttributes={updateCollectionAttributes} normalizeNumber={normalizeNumber} cRoyaltyFlag={cRoyaltyFlag} updateCRoyaltyFlag={updateCRoyaltyFlag} dropOptions={dropMenuOption || []}/> 

            {alert.state && <ErrorMessage>*{`${errorMesages[alert.element as keyof Object]}`}</ErrorMessage>}
            {props.type !== "edit" ? '' : (
                <Draft><DraftText state={collectionData.Launched}>{collectionData.Launched ? "Launched" : "Draft"}</DraftText><SwitchButton state={collectionData.Launched} size="25" changeState={changeDraftState} /></Draft>
            )}

            <ButtonsArea collectionData={collectionData} type={props.type} handleSave={handleSave} handleDelete={handleDelete} handleCancel={handleCancel} />

        </FormCollection>
    );
}



const FormCollection = styled.div`
    box-sizing: border-box;
    padding: 40px 20px 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    width: 100%;
    max-width: 900px;
`

const Title = styled.div`
    & h2{
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        color: #830F5B;
        margin: 0;
    }
`

const RequiredAdd = styled.div`
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    & p{
        margin: 0;
    }
`

const PurpleDot = styled.span`
    &::before{
        color: #830F5B;
        content: "*";
    }
`

const Draft = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;
`
const DraftText = styled.p<{ state: boolean }>`
    color: ${({ state }) => state ? "#84004C" : "#9B9B9B"};
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 26.5497px;
    line-height: 32px;
    margin: 0px;
`

const ErrorMessage = styled.p`
    margin: 0;
    color: #c71111;
    flex: 1;
    text-align: left;
`