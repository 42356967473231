import React, { useState, useContext, useEffect } from "react";
import UsedDateContex from '../../context/usedDatesContext2';
import CurrentDate from '../../context/currentDateContext'
import './Day.css'

interface widgetProps {
    date: string,
    globalDate: string,
    children: React.ReactNode
}


export const oldDateCheck = (selected: string, referer: string) => {
    let [selectedDay, selectedMonth, selectedYear] = selected.split("/")
    let [referernDay, refererMonth, refererYear] = referer.split("/")
    if ((+selectedDay < +referernDay && +selectedMonth <= +refererMonth && +selectedYear === +refererYear) ||
        (+selectedMonth < +refererMonth && +selectedYear <= +refererYear) ||
        +selectedYear < +refererYear) return true;
    return false;
}

export const filterBungalows = (usedDateDay: any, Persons: number, Bungalows: number, currentDate: any) => {
    let availableBungalows = usedDateDay.AvailableBungalows.filter((bungalow: any) => {
        let { MaxPeople, AvailableQuantity } = bungalow;
        //if (AvailableQuantity && Persons <= MaxPeople) return true;
        //return false;
        if (AvailableQuantity >= Bungalows) {
            return true;
        }
        return false
    })
    return availableBungalows;
}

export default function Day(props: widgetProps) {
    let [day, month, year] = props.date.split("/");
    let usedDateContex = useContext(UsedDateContex);
    let currentDateContext = useContext(CurrentDate);

    let { StartDate, EndDate, OneDay, Persons, MaxDate, Bungalows } = currentDateContext.Data;

    let [dinamicClass, setDinamicClass] = useState("dayClass")
    let [stateClass, setStateClass] = useState("");
    //console.log(usedDateContex)

    useEffect(() => {
        let dates = usedDateContex.dates;
        if (dates) {
            let existInUsedDates = dates.hasOwnProperty(year) && dates[year].hasOwnProperty(month) && dates[year][month].hasOwnProperty(day);
            if (existInUsedDates) {
                let { Date, State, AvailableBungalows } = dates[year][month][day];
                if (State === "Full" || AvailableBungalows.length === 0) {
                    setDinamicClass("disabledDay");
                    setStateClass("");
                }
                else if (State === "Almost") setStateClass("almost");
                else if (State === "Several") setStateClass("several")
                else setStateClass("")
            }

            else setStateClass("");
        }
    }, [usedDateContex])

    useEffect(() => {
        let beforeGlobalDate = oldDateCheck(props.date, props.globalDate);
        if (beforeGlobalDate) setDinamicClass("disabledDay")
    }, [])


    useEffect(() => {
        let dates = usedDateContex.dates;
        if (dates) {
            let existInUsedDates = dates.hasOwnProperty(year) && dates[year].hasOwnProperty(month) && dates[year][month].hasOwnProperty(day);
            let usedDateDay = existInUsedDates ? dates[year][month][day] : null;
            let availableBungalows = usedDateDay ? filterBungalows(usedDateDay, Persons, Bungalows, props.date) : [];
            let haveBungalows = existInUsedDates && availableBungalows.length
            let dontHaveBungalows = existInUsedDates && !availableBungalows.length


            let beforeGlobalDate = oldDateCheck(props.date, props.globalDate);
            //let isDayAfterLimit = MaxDate.length ? oldDateCheck(MaxDate, props.date) : false;

            if (beforeGlobalDate) setDinamicClass("disabledDay")
            else {

                let beforeStartDay = StartDate.length ? oldDateCheck(props.date, StartDate) : false;
                let afterStartDay = StartDate.length ? oldDateCheck(StartDate, props.date) : false;
                let beforeEndDay = EndDate.length ? oldDateCheck(props.date, EndDate) : false;
                let afterEndDay = EndDate.length ? oldDateCheck(EndDate, props.date) : false
                let pointDays = (StartDate === props.date || EndDate === props.date);
                let matchStart = (StartDate === props.date);
                let matchEnd = (EndDate === props.date);

                if (!OneDay) {

                    if (!StartDate && !EndDate) {
                        if (MaxDate) currentDateContext.updateData({ ...currentDateContext.Data, MaxDate: "" })
                        if (dontHaveBungalows) setDinamicClass("disabledDay");
                        if (haveBungalows || !existInUsedDates) setDinamicClass("dayClass");
                        if (currentDateContext.Data.BetweenDays.length) {
                            currentDateContext.updateData({ ...currentDateContext.Data, BetweenDays: [] });
                        }
                    }


                    if (StartDate && !EndDate) {
                        if (beforeStartDay) setDinamicClass("disabledDay");

                        if (!beforeStartDay) {

                            if (dontHaveBungalows) {
                                if (matchStart) {
                                    let newData = { ...currentDateContext.Data };
                                    setDinamicClass("disabledDay");
                                    newData.StartDate = "";
                                    newData.EndDate = "";
                                    currentDateContext.updateData(newData);
                                }

                                if (!matchStart) {
                                    if (!MaxDate.length) {
                                        currentDateContext.updateData({ ...currentDateContext.Data, MaxDate: props.date })
                                        if (afterStartDay) setDinamicClass("dayClass");
                                        if (beforeStartDay) setDinamicClass("disabledDay");

                                    }

                                    if (MaxDate) {
                                        if (!oldDateCheck(MaxDate, props.date)) {
                                            if (MaxDate !== props.date) currentDateContext.updateData({ ...currentDateContext.Data, MaxDate: props.date });
                                            if (afterStartDay) setDinamicClass("dayClass");
                                            if (beforeStartDay) setDinamicClass("disabledDay");
                                        }

                                        if (oldDateCheck(MaxDate, props.date)) setDinamicClass("disabledDay");

                                    }
                                }
                            }

                            if (!existInUsedDates || haveBungalows) {

                                if (!MaxDate.length) {
                                    if (matchStart) setDinamicClass("dayClassUnique");
                                    if (afterStartDay) setDinamicClass("dayClass");
                                }

                                if (MaxDate) {
                                    let afterMaxDay = oldDateCheck(MaxDate, props.date)
                                    if (afterMaxDay) setDinamicClass("disabledDay");
                                    if (!afterMaxDay && afterStartDay) setDinamicClass("dayClass");
                                    if (!afterMaxDay && matchStart) setDinamicClass("dayClassUnique")
                                }
                            }
                        }
                        if (currentDateContext.Data.BetweenDays.length) {
                            currentDateContext.updateData({ ...currentDateContext.Data, BetweenDays: [] });
                        }
                    }


                    if (!StartDate && EndDate) {
                        if (MaxDate) currentDateContext.updateData({ ...currentDateContext.Data, MaxDate: "" })
                        if (afterEndDay) setDinamicClass("disabledDay");
                        if (matchEnd) setDinamicClass("dayClassUnique");
                        if (beforeEndDay) setDinamicClass("dayClass");
                        if (dontHaveBungalows) setDinamicClass("disabledDay");
                        if (currentDateContext.Data.BetweenDays.length) {
                            currentDateContext.updateData({ ...currentDateContext.Data, BetweenDays: [] });
                        }
                    }


                    if (StartDate && EndDate) {
                        if (MaxDate) currentDateContext.updateData({ ...currentDateContext.Data, MaxDate: "" })
                        let newData = { ...currentDateContext.Data };

                        if (StartDate === EndDate) {
                            if (dontHaveBungalows && matchStart) {
                                setDinamicClass("disabledDay");
                                newData.StartDate = "";
                                newData.EndDate = "";
                                currentDateContext.updateData(newData);
                            }
                            if (dontHaveBungalows && !matchStart) {
                                setDinamicClass("disabledDay");
                                newData.EndDate = "";
                                currentDateContext.updateData(newData)
                            }
                            if ((haveBungalows || !existInUsedDates) && matchStart) {
                                setDinamicClass("dayClassUnique");
                                newData.EndDate = "";
                                currentDateContext.updateData(newData)
                            }
                            if ((haveBungalows || !existInUsedDates) && !matchStart) {
                                setDinamicClass("dayClass");
                                newData.EndDate = "";
                                currentDateContext.updateData(newData)
                            }
                        }

                        if (StartDate !== EndDate) {
                            if (dontHaveBungalows) {
                                if (beforeStartDay) setDinamicClass("disabledDay");
                                if (afterEndDay) setDinamicClass("disabledDay");
                                if (matchEnd) setDinamicClass("dayClassEnd");
                                if (!beforeStartDay && beforeEndDay) {
                                    setDinamicClass("disabledDay");
                                    newData.StartDate = "";
                                    newData.EndDate = "";
                                    currentDateContext.updateData(newData);
                                }
                            }

                            if ((haveBungalows || !existInUsedDates)) {
                                if (beforeStartDay) setDinamicClass("dayClass");
                                if (afterEndDay) setDinamicClass("dayClass");
                                if (matchStart) setDinamicClass("dayClassStart clickable");
                                if (matchEnd) setDinamicClass("dayClassEnd clickable");
                                if (afterStartDay && beforeEndDay) {
                                    if (!newData.BetweenDays.includes(props.date)) {
                                        newData.BetweenDays.push(props.date);
                                        currentDateContext.updateData(newData);
                                    }
                                    setDinamicClass("betweenDays");
                                }
                            }
                        }
                    }
                }

                else {
                    if (pointDays) setDinamicClass("dayClassUnique");
                    else setDinamicClass("dayClass");
                }
            }
        }

    }, [currentDateContext.Data])




    let clickedDate = () => {
        //if (!oldDateCheck(props.date, props.glogalDate)) {
            
        if (dinamicClass !== "disabledDay") {

            let newData = currentDateContext.Data;
            if (!OneDay) {
                let start = newData.StartDate;
                let end = newData.EndDate;
                if (start !== props.date || end.length) {
                    if (!start.length || (!oldDateCheck(props.date, start) && !end.length) || (start.length && end.length)) {
                        if ((!start.length && !end.length) || (start.length && end.length) || (!start.length && end.length)) {
                            newData.StartDate = props.date;
                            newData.EndDate = "";
                            currentDateContext.updateData(newData)
                        }
                        if (start.length && !end.length) {
                            newData.EndDate = props.date;
                            currentDateContext.updateData(newData)
                        }
                    }
                }
            }
            else {
                if (props.date !== StartDate) {
                    newData.StartDate = props.date;
                    newData.EndDate = props.date;
                    currentDateContext.updateData(newData)
                }
            }

            if(StartDate === props.date && !EndDate.length){
                newData.StartDate = "";
                newData.EndDate = "";
                currentDateContext.updateData(newData);
            }

        }
    }

    return (
        <div className={`mcontainerDayDiv mcontainer__${dinamicClass}`}>
            <div className={`mdayDiv m${dinamicClass} m${stateClass}`} onClick={clickedDate}>
                {props.children}
            </div>
            {/* <div>{limitedDay}</div> */}
        </div>
    );
}
