import axios from 'axios'
import { app } from '../../config/config'
let BACKEND_URL = app.BACKEND_URL;

if (process.env.BACKEND_URL) BACKEND_URL = process.env.BACKEND_URL


export interface TShortCollection {
    id: string,
    name: string,
    price: string,
    status: string,
    image: string
}
export interface TCollectionsList {
    collections: Array<TShortCollection>
}

export interface TApiResponseCollection {
    "currencies": String,
    "status": String,
    "royalty_wallet_address": String,
    "collection_name": String,
    "token_image": String | Array<string>,
    "id_item": String,
    "creator_wallet": String,
    "category": String,
    "logo_image": String,
    "description": String,
    "price": Number,
    "additional_image": String | Array<string>,
    "royalty_percentage": Number,
    "banner_image": String
}

export interface TApiResponseList {
    data: {
        items: Array<TApiResponseCollection>,
        count: Number,
        totalResults: Number
    }
}

export async function getCollectionsList() {

    const axiosOptions = {
        method: 'GET',
        withCredentials: true,
        url: `${BACKEND_URL}/api/collections`
    };



    try{
        const resp = await axios(axiosOptions)
        return resp;
    }
    catch(error){
        console.log(error)
    }

    /*return new Promise<AxiosResponse<TApiResponseList>>((resolve, reject) => {
        axios(axiosOptions)
            .then(function (response) {
                // handle success
                resolve(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error)
                reject('No possible to get collections')
            })
    })*/
}

export function getLocalCollectionsList() {
    let localCollection: TCollectionsList = {
        collections: [
            {
                id: "1",
                name: "Amazonia - Sebastiao Salgado",
                price: "2 eTH",
                status: "Launched",
                image: "https://img.freepik.com/free-photo/red-wine_144627-33208.jpg"
            },
            {
                id: "2",
                name: "Amazonia - Sebastiao Salgado",
                price: "2 eTH",
                status: "Draft",
                image: "https://img.freepik.com/free-vector/closed-blank-flask-wineglass-with-alcohol-vine-drink-burgundy-liquid-splashes-droplets_33099-1671.jpg"
            },
            {
                id: "3",
                name: "Amazonia - Sebastiao Salgado",
                price: "4 eTH",
                status: "Launched",
                image: "https://img.freepik.com/free-psd/labeling-wine-mockup-design_23-2149456888.jpg"
            }
        ]
    }

    return localCollection
}