import React, { useEffect, useState } from "react";
import { getDropmenuOptions } from "../../services/collections/collectionReq";
import CustomDropMenu from '../collection/customDropMenu'
import { SectionForm, SectionTitle, TwoSectionSplit, PurpleDot } from './stylingForm'
import { TDropOptions } from "../collection/collectionForm";

/*const categoriesOptions = [
    {
        name: "Wine",
        active: true
    },
    {
        name: "Liquor",
        active: true
    },
    {
        name: "Merchandise",
        active: true
    },
    {
        name: "Collectible",
        active: true
    },
    {
        name: "Membership",
        active: true
    }
]

const creatorWallerOptions = [
    {
        name: "iReserve - F772",
        active: true
    },
    {
        name: "iReserve - A54B",
        active: true
    },
    {
        name: "VRC - 6522",
        active: true
    }
]*/

interface widgetProps { collectionData: any, updateCollectionAttributes: Function, categoryFlag: boolean, updateCategoryFlag: Function, cWalletFlag: boolean, updateCWalletFlag: Function, dropOptions:any}
export default function CategoryAndWallet(props: widgetProps) {
    let { collectionData, updateCollectionAttributes, categoryFlag, cWalletFlag, updateCategoryFlag, updateCWalletFlag, dropOptions } = props;
    let [creatorWalletOptions, setCreatorWalletOptions] = useState([])
    
    const updateCategory = (elements: Array<string>) => {updateCollectionAttributes("Category", elements) };
    const updateCreatorWallet = (elements: Array<string>) => {updateCollectionAttributes("CreatorWallet", elements) };


    
    useEffect(() => {
        //console.log(collectionData.CreatorWallet)
        if(!collectionData.Category || collectionData.Category.length===0 ) {
            setCreatorWalletOptions([]);
            updateCollectionAttributes("CreatorWallet", "")
        }
        else{
            let filteredCreatorOptions = dropOptions["creator_wallet"].filter((wallet:TDropOptions) => {return wallet.category?.toLowerCase() === collectionData.Category.toLowerCase()})
            //console.log({dropOptions:dropOptions["creator_wallet"], filteredCreatorOptions })
            setCreatorWalletOptions(filteredCreatorOptions);
            const firstInit = dropOptions["creator_wallet"][0] === "iReserve - A54B";
            if(filteredCreatorOptions.length === 0 && !firstInit) updateCollectionAttributes("CreatorWallet", "")
        }
    }, [collectionData.Category, dropOptions])



    return (
        <TwoSectionSplit>
            <SectionForm>
                <SectionTitle>Category <PurpleDot /></SectionTitle>
                <CustomDropMenu selected={collectionData.Category} options={dropOptions.category} type="simple" updateSelected={updateCategory} dropFlag={categoryFlag} updateDropFlag={updateCategoryFlag} customDisabled={collectionData.Launched} />
            </SectionForm>
            <SectionForm>
                <SectionTitle>Creator Wallet <PurpleDot /></SectionTitle>
                <CustomDropMenu selected={collectionData.CreatorWallet} options={creatorWalletOptions} type="simple" updateSelected={updateCreatorWallet} dropFlag={cWalletFlag} updateDropFlag={updateCWalletFlag} customDisabled={collectionData.Launched} />
            </SectionForm>
        </TwoSectionSplit>
    );
}