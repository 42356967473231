import React, { useEffect, useLayoutEffect, useState } from 'react'
import logo from './logo.svg'
import Cookies from 'universal-cookie'

import { useAppSelector, useAppDispatch } from '../app/hooks'
import { requestUserInfo, selectUserInfo } from '../services/user/userSlice'

import { Route, Routes, useNavigate } from "react-router-dom"
import './App.css'
import '../assets/css/font-family.css'

import Sidebar from '../components/sidebar/sidebar'
import Events from '../views/events/Events'
import Users from '../views/usersManagement/Users'
import Channels from '../views/Channels'
import Channels2 from '../views/Channels2'
import Verifying from '../views/Verifying'
import NotFound404 from '../views/404/404'
import Collections from '../views/collections/Collections'
import NewCollections from '../views/collections/NewCollection'
import EditCollections from '../views/collections/EditCollection'
import Wallets from '../views/wallets/Wallets'
import NewWallet from '../views/wallets/NewWallet'
import EditWallet from '../views/wallets/EditWallet'
import Reservations from '../views/reservations/Reservations'
import Applications from '../views/applications/Applications'
import Payments from '../views/payments/Payments'

import { useLocation, } from "react-router-dom"
import EditReservation from '../views/reservations/EditReservation'
import NewEvent from '../views/events/NewEvent'
import EditEvent from '../views/events/EditEvent'

import PopUp from '../components/popUp/popUp'

const cookies = new Cookies();

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector(selectUserInfo);
  const sampleLocation = useLocation();
  const [islogged, setIslogged] = useState(typeof cookies.get('vrc_admin') !== 'undefined');

  useLayoutEffect(() => {
    setIslogged(typeof cookies.get('vrc_admin') !== 'undefined')
  }, [userInfo])

  useLayoutEffect(() => {
    dispatch(requestUserInfo());
  }, [sampleLocation.pathname]);

  if (islogged) {
    return (
      <div className="App">

        <div className='sidebar' >
          <Sidebar />
        </div>

        <div className='main'>
          <Routes>
            <Route path="/" element={<Events />} />
            <Route path="/users" element={<Users />} />
            <Route path="/channels" element={<NotFound404 />} />
            <Route path="/channels2" element={<NotFound404 />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/new" element={<NewEvent />} />
            <Route path="/events/:id-item" element={<EditEvent />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/collections/new" element={<NewCollections />} />
            <Route path="/collections/:id-item" element={<EditCollections />} />
            <Route path="/wallets" element={<Wallets />} />
            <Route path="/wallets/new" element={<NewWallet />} />
            <Route path="/wallets/:id-item" element={<EditWallet />} />
            <Route path="/reservations" element={<Reservations />} />
            <Route path="/reservations/:id-item" element={<EditReservation />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/payments" element={<Payments/>} />
          </Routes>
        </div>

        <PopUp/>

      </div>
    );

  } else {
    if (window.location.pathname !== '/') {
      navigate("/");
    }

    return (
      <div className="App">
        <div className="main">
          <Routes>
            <Route path="/" element={<Verifying />} />
          </Routes>
        </div>
      </div>
    )
  }
}

export default App;
