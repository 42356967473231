import React, { useLayoutEffect, useState } from 'react';
import './Users.css'
import axios from 'axios';
import Chat from '../../components/chat/Chat'
import { app } from '../../config/config'
import SwitchButton2 from '../../components/switchButton/switchButton2';
import PenGray from '../../assets/icons/penGray.png'
import ModalConfirmation from '../../components/modals/modalConfimation';
import ModalUserEdition from '../../components/modals/modalUserEdition';

let BACKEND_URL = app.BACKEND_URL;


type subscribers = {
  phone_number: string,
  email: string,
  username: string,
  status: string,
  "custom:role": string,
  enabled: boolean
}

export type TLocalSuscribers = {
  UserName: string,
  Email: string,
  PhoneNumber: string,
  UserRole: string,
  Status: boolean
}

const uploadUserDataInApi = (userName: string, payload: object) => {
  const options = {
    method: 'PUT',
    withCredentials: true,
    url: `${BACKEND_URL}/api/admin/users/${userName}`,
    data: payload
  };
  console.log(options)
  axios(options)
    .then(function (response) {
      console.log("updated successfully")
      console.log(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
}

function Users() {
  const [subscribers, setSubscribers] = useState<TLocalSuscribers[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | undefined>();
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [editUserModalState, setEditUserModalState] = useState(false);

  useLayoutEffect(() => {
    loadSubscribers()
  }, [])

  function loadSubscribers() {
    const options = {
      method: 'GET',
      withCredentials: true,
      url: `${BACKEND_URL}/api/admin/users`
    };

    axios(options)
      .then(function (response) {
        let adjustedData = response.data.items.map((item: subscribers) => {
          return {
            UserName: item.username,
            Email: item.email,
            PhoneNumber: item.phone_number,
            UserRole: item["custom:role"],
            //Status: (item.status == "CONFIRMED") ? true : false,
            Status: item.enabled
          }
        })
        setSubscribers(adjustedData)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  

  const openStateVerificationModal = (idx: number) => {
    setSelectedUser(idx);
    setEditUserModalState(false);
    setConfirmModalState(true);
  }
  const openEditModal = (idx: number) => {
    setSelectedUser(idx);
    setEditUserModalState(true);
    setConfirmModalState(false);
  }
  const closeAllModals = () => {
    setSelectedUser(undefined);
    setEditUserModalState(false);
    setConfirmModalState(false)
  }

  function changeUserStatus(idx: number | undefined) {
    if (idx !==undefined) {
      let newSuscribersState = [...subscribers];
      newSuscribersState[idx].Status = !subscribers[idx].Status;
      
      let payloadStatus = newSuscribersState[idx].Status ? { "type": "enableUser" } : { "type": "disableUser" }
      let userName = newSuscribersState[idx].UserName;
      uploadUserDataInApi(userName,payloadStatus);
      
      setSubscribers(newSuscribersState)
    }
  }
  const confirmChangeStatus = (idx: number | undefined) => {
    changeUserStatus(idx);
    closeAllModals();
  }

  const updateUserData = (index: number, data: TLocalSuscribers) => {
    let newSubscribers = [...subscribers];
    newSubscribers[index] = data;
    let userName = newSubscribers[index].UserName;

    if (newSubscribers[index].Status !== subscribers[index].Status) {
      console.log("updating status")
      let payloadStatus = newSubscribers[index].Status ? { "type": "enableUser" } : { "type": "disableUser" }
      uploadUserDataInApi(userName,payloadStatus);
    }

    if (newSubscribers[index].UserRole !== subscribers[index].UserRole) {
      console.log("updating role")
      let payloadRole = {
        "type": "changeRole",
        "role": newSubscribers[index].UserRole
      }
      uploadUserDataInApi(userName,payloadRole);
    }

    setSubscribers(newSubscribers);
    closeAllModals();

  }

  const confirmModalParams = {
    closeModal: closeAllModals,
    confirmModal: () => { confirmChangeStatus(selectedUser) },
    title: "CHANGE USER STATUS?",
    acceptText: "CHANGE",
    cancelText: "CANCEL"
  }
  const userEditionParams = {
    id: selectedUser,
    userData: selectedUser != undefined ? subscribers[selectedUser] : {},
    updateUserData: updateUserData,
    closeModal: closeAllModals
  }
  return (
    <div className="users-main">
      {confirmModalState && <ModalConfirmation {...confirmModalParams} />}
      {editUserModalState && <ModalUserEdition {...userEditionParams} />}
      <div className="users-titleSection">
        <h1 className="users-title">Users List</h1>
      </div>

      <div className='users-subscribers-table'>
        <table>
          <tbody>
            <tr>
              <th className='user-leftPad'>Username</th>
              <th className='user-leftPad'>Email</th>
              <th>Phone Number</th>
              <th className='userTitle-small'>User Role</th>
              <th className='userTitle-small'>Status</th>
              <th className='userTitle-small'></th>
            </tr>

            {subscribers.map((sub, idx) => (
              <tr
                key={`sub${idx}`}
              >
                <td className="user-leftPad user-userName"> {sub.UserName} </td>
                <td className="user-leftPad"> {sub.Email} </td>
                <td className="user-PhoneNumber"> {sub.PhoneNumber} </td>
                <td className="user-UserRole"> {sub.UserRole} </td>
                <td className="user-Status"> <SwitchButton2 state={sub.Status} size="18" changeState={() => { openStateVerificationModal(idx) }} /> </td>
                <td className="user-edit"><img src={PenGray} alt="edit-user" onClick={() => { openEditModal(idx) }} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* <Chat/> */}

    </div>
  );
}

export default Users;
