import axios, { AxiosResponse } from 'axios';
import { app } from '../../config/config'
let BACKEND_URL = app.BACKEND_URL;

interface Tfile { blob: Blob, dataUrl: string, file: File };


export async function onFileChange(event: React.SyntheticEvent) {
  const target = event.target as typeof event.target & {
    files: FileList;
  };

  const filesRaw: File[] = []

  for (let file in target.files) {
    if (typeof target.files[file] === 'object') {
      filesRaw.push(target.files[file])
    }
  }

  const files = filesRaw.map(file => {
    const reader = new FileReader()

    return new Promise<Tfile>(resolve => {
      reader.onload = (e) => {
        if (e.target?.result) {
          let binary = window.atob(e.target.result.toString().split(',')[1])
          let array = []
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i))
          }

          let blobData = new Blob([new Uint8Array(array)], { type: '.jpeg' })
          resolve({ blob: blobData, dataUrl: e.target.result.toString(), file })
        }
      }

      reader.readAsDataURL(file)
    })
  })

  const resultFiles = await Promise.all(files)
  return ([...resultFiles]);
}





interface TpayloadImagePost {
  type: "uploadImage",
  collection_id: string,
  file_type: string,
  image_name: string,
  title?: string,
  desc?: string,
  amount?: number,
  additional_desc?: string
}
interface TgetPresignedUrlProps { collection_id: string, file_type: string, image_name: string, token_info?: any }
export function getPresignedUrl(props: TgetPresignedUrlProps) {
  const { collection_id, file_type, image_name, token_info } = props;

  const payload: TpayloadImagePost = {
    type: "uploadImage",
    collection_id, file_type, image_name,
  };

  if (token_info) {
    payload.title = token_info.title;
    payload.desc = token_info.desc || token_info.description || "";
    payload.amount = token_info.amount !== undefined ? token_info.amount : token_info.quantity || "";
    payload["additional_desc"] = token_info["additional_desc"] !== undefined ? token_info["additional_desc"] : token_info.additionalDescription || "";
  }

  const axiosOptions = {
    url: `${BACKEND_URL}/api/collections`,
    method: 'POST',
    data: payload,
    withCredentials: true,
  }


  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios(axiosOptions)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        reject('No possible to update inspection')
      })
  })
}





export function updateInspeccion(toUpdate: any) {
  const payload = {
    type: "updateInspeccion",
    ...toUpdate,
  };

  const axiosOptions = {
    method: 'POST',
    data: payload,
    withCredentials: true,
    url: `${BACKEND_URL}/api/collections`
  };

  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios(axiosOptions)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        reject('No possible to update inspection')
      })
  })
}



export function uploadImage(file: File | Blob, slug: string, type: string) {

  return new Promise<any>((resolve, reject) => {
    fetch(slug, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: file
    })
      .then(data => {
        resolve({ result: 'success' })
      })
      .catch((error) => console.log('userInfo error', error));
  })
}


export function uploadFoto(id: string | undefined, file_type: string, image_name: string, files: any, token_info?: any) {
  const collection_id = id;

  if (collection_id && file_type) {
    getPresignedUrl({ collection_id, file_type, image_name, token_info })
      .then((resp:any) => {
        uploadImage(files, resp.urlS3Image, file_type)
          .then(() => {
            updateInspeccion({
              task_id: collection_id,
              [`${image_name}`]: resp.urlS3Image,
            })
              .then((respon) => {
                return true;
              })
          })
      })
  }
}


export function deleteImage(id: string, image_name: string) {
  
  const axiosOptions = {
    method: 'DELETE',
    withCredentials: true,
    url: `${BACKEND_URL}/api/collections/${id}/images/${image_name}`,
  };


  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios(axiosOptions)
      .then(function (response) {
        return (response);
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        reject('No possible to delete image')
      })
  })
}

interface Tparameters {
  title: string,
  desc: string,
  amount: number
}
export function updateToken(id: string, token_id: string, parameters: Tparameters) {
  const axiosOptions = {
    method: 'PUT',
    withCredentials: true,
    url: `${BACKEND_URL}/api/collections/${id}/token/${token_id}`,
    data: parameters
  };

  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios(axiosOptions)
      .then(function (response) {
        return (response);
      })
      .catch(function (error) {
        reject('No possible to update token info')
      })
  })
}