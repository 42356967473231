import React, { useEffect, useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { RootState, useAppDispatch, useAppSelector } from '../../app/store'
import useViewport from '../../hooks/useViewport'

import { popUpState, TpopUpTypes } from './types'
import { setLogEvent } from './popUpSlice'

import CloseIcon from '../../assets/icons/closeIcon.png'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}

interface logEvent extends popUpState {
  timeOut: ReturnType<typeof setTimeout>
}

export default function PopUp() {
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  const [logs, setLog] = useState<logEvent[]>([])
  const [cleanLog, setCleanLog] = useState<number|undefined>(undefined)
  const logEvents = useAppSelector((state: RootState) => state.popUp)
  const timeOut = 5000;

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)

  }, [bodyWidth, bodyTop])

  // LOADING LOG EVENT FROM REDUX
  useEffect(() => {
    const newLog = { ...logEvents }

    if (newLog.message !== undefined) {
      setLog([...logs, {
        ...newLog,
        timeOut: setTimeout(() => {
          if (newLog.timestamp)
          removeLog(newLog.timestamp)
        }, timeOut),
      }])
    }

  }, [logEvents])

  // REMOVING OLD LOGS
  function removeLog(logId: number) {
    setLog((state) => {
      const findLog = state.findIndex((item) => item.timestamp === logId)

      if (findLog > -1) {
        state.splice(findLog, 1)
        return [...state]
      }
      else return state
    })
  }

  useEffect(() => {
    const logToRemove = cleanLog

    if (logToRemove) {
      setLog((state) => {
        const findLog = state.findIndex((item) => item.timestamp === logToRemove)

        if (findLog > -1) {
          state.splice(findLog, 1)
          return [...state]
        }
        else return state
      })
    }
  }, [cleanLog])

  return(
    <PopUpContainer isMobile={isMobile}>
      { logs.map((log, index) => (
          <Notification
            key={`popup-log-${index}`}
            notificationType={log.type ? log.type : 'error'}
            isMobile={isMobile}
          >

            { log.message }

            <CloseContainer
              onClick={() => log.timestamp && removeLog(log.timestamp)}
            >
              <CloseImg src={CloseIcon}/>
            </CloseContainer>

          </Notification>
        ))
      }
    </PopUpContainer>
  )
}

interface isMobile { isMobile: boolean }
const PopUpContainer = styled.div<isMobile>`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1001;
  ${(args) => args.isMobile && `
    right: 10px;
  `}
`

interface Tnotification { notificationType: TpopUpTypes, isMobile: boolean }
const Notification = styled.div<Tnotification>`
  background-color: red;
  width: 500px;
  min-height: 30px;
  color: white;
  font-family: Montserrat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  border-radius: 5px;
  margin-top: 10px; 
  box-shadow: 5px 5px 15px black;
  text-align: left;
  ${(args) => args.notificationType === 'info' && `
    background-color: #B8006B;
  `}
  ${(args) => args.isMobile && `
    width: 300px;
  `}
`

  const CloseContainer = styled.div`
    cursor: pointer;
  `
    const CloseImg = styled.img`
      width: 30px;
      cursor: pointer;
    `