import React, { useState, useEffect } from "react";
import { SectionForm, SectionTitle, TwoSectionSplit, PurpleDot, TextInputArea, PercentSymbol } from './stylingForm'
import CustomDropMenu from '../collection/customDropMenu';
import { TDropOptions } from "../collection/collectionForm";

interface widgetProps { 
    collectionData: any, 
    updateCollectionAttributes: Function, 
    normalizeNumber: Function,
    cRoyaltyFlag: boolean,
    updateCRoyaltyFlag: Function,
    dropOptions: any
}
export default function Royalty(props: widgetProps) {
    let { collectionData, updateCollectionAttributes, normalizeNumber, cRoyaltyFlag, updateCRoyaltyFlag, dropOptions} = props;
    let [royaltyWalletOptions, setRoyaltyWalletOptions] = useState([])
    
    let updateRoyaltyWallet = (elements: Array<string>) => {updateCollectionAttributes("RoyaltyWallet", elements) };
    let changeRoyaltyPercentage = (event: any) => {
        if (Number(event.target.value) <= 100 && Number(event.target.value) >= 0)
            updateCollectionAttributes("RoyaltyPercentage", event.target.value)
    }


    useEffect(() => {
        if(!collectionData.Category || collectionData.Category.length===0 ) {
            setRoyaltyWalletOptions([]);
            updateCollectionAttributes("RoyaltyWallet", "")
        }
        else{
            let filteredRoyaltyOptions = dropOptions["creator_wallet"].filter((wallet:TDropOptions) => wallet.category?.toLowerCase() === collectionData.Category.toLowerCase())
            setRoyaltyWalletOptions(filteredRoyaltyOptions)
            const firstInit = dropOptions["creator_wallet"][0] === "iReserve - A54B";
            if(filteredRoyaltyOptions.length === 0 && !firstInit) updateCollectionAttributes("RoyaltyWallet", "");
        }
    }, [collectionData.Category, dropOptions])



    return (
        <TwoSectionSplit>
            <SectionForm>
                <SectionTitle>royalty wallet address <PurpleDot /></SectionTitle>
                {/* <TextInputArea type="text" value={collectionData.RoyaltyWallet} onChange={changeRoyaltyWallet} withLogo={false} disabled={collectionData.Launched} /> */}
                <CustomDropMenu selected={collectionData.RoyaltyWallet} options={royaltyWalletOptions} type="simple" updateSelected={updateRoyaltyWallet} dropFlag={cRoyaltyFlag} updateDropFlag={updateCRoyaltyFlag} customDisabled={collectionData.Launched} />
            
            </SectionForm>
            <SectionForm>
                <SectionTitle>royalty percentage <PurpleDot /></SectionTitle>
                <TextInputArea type="number" value={collectionData.RoyaltyPercentage} onChange={changeRoyaltyPercentage} onBlur={()=>{normalizeNumber()}} withLogo={true} disabled={collectionData.Launched} />
                <PercentSymbol>%</PercentSymbol>
            </SectionForm>
        </TwoSectionSplit>
    );
}