import React, { useState, useEffect } from "react";
import { getOcupedDates, getLocalDates } from '../services/reservations/booking';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../app/store'

export interface widgetProps { children: React.ReactNode };

export type NewDateUpdatedType = {
    StartDate: string,
    EndDate: string,
    Persons: number,
    OneDay: boolean,
    BetweenDays: Array<string>,
    Bungalows: number
}

export type NewDateType = {
    Data: {
        StartDate: string,
        EndDate: string,
        Persons: number,
        Bungalows: number,
        OneDay: boolean,
        MaxDate: string,
        BetweenDays: Array<string>
    },
    updateData: Function
}

export type BoungalowType = {
    Type: string,
    AvailableQuantity: number
    MaxPeople: number
}

export type DayType = {
    Date: string,
    State: string,// <Full/Almost/Several/Empty>
    AvailableBungalows: Array<BoungalowType>
}

export type MonthType = { [key: string]: DayType }
export type YearType = { [key: string]: MonthType }
export type DatesType = { [key: string]: YearType }

export type ContextType = {
    dates: DatesType,
    updateDates: Function,
    createDates: Function,
    reloadDates: Function,
    resetDates: Function,
    updateUsedDates: Function
};


const initialDates: ContextType = {
    dates: {},
    updateDates: (date: object) => { },
    createDates: (dates: DatesType) => { },
    reloadDates: () => { },
    resetDates: () =>{},
    updateUsedDates: () => {}
}


export const setAvailableBungalow = (amount: Number) => {
    if (amount == 0) return "Full"
    if (amount < 5) return "Almost"
    if (amount < 10) return "Several"
    else return "Empty"
}

const oldDateCheck = (selected: string, referer: string) => {
    let [selectedDay, selectedMonth, selectedYear] = selected.split("/")
    let [referernDay, refererMonth, refererYear] = referer.split("/")
    if ((+selectedDay < +referernDay && +selectedMonth <= +refererMonth && +selectedYear === +refererYear) ||
        (+selectedMonth < +refererMonth && +selectedYear <= +refererYear) ||
        +selectedYear < +refererYear) return true;
    return false;
}

function previousMonthDate(date: string) {
    const [day, month, year] = date.split("/");
    if ((+year === 0 || +year === 1) && +month === 1) return `31/12/9999`
    if (+month === 1) return `1/12/${+year - 1}`;
    return `1/${+month - 1}/${year}`;
}

function nextMonthDate(date: string) {
    const [day, month, year] = date.split("/");
    if (+year === 9999 && +month === 12) return `1/1/0001`
    if (+month === 12) return `1/1/${+year + 1}`;
    return `1/${+month + 1}/${year}`;
}

function isMonthInContext(date: string, usedDates: DatesType) {
    if (date.match(/\d+\/\d+\/\d+/)) {
        const [day, month, year] = date.split("/");
        if (usedDates.hasOwnProperty(year)) {
            if (usedDates[year].hasOwnProperty(month)) {
                return true;
            }
        }
    }
    return false;
}

// If the dates month is not registered in usedDatesContext it will make a call to get data from the specified month and adapt it to usedContext format
async function addNewDate(newDate: string, bookingContext: DatesType, fixedTodayDate: string) {
    let [searchDay, searchMonth, searchYear] = newDate.split("/");
    let newBookingObject: DatesType = { ...bookingContext };
    let contextMonthValidation = isMonthInContext(newDate, bookingContext);
    

    if (!contextMonthValidation) {

        if (!newBookingObject.hasOwnProperty(searchYear)) newBookingObject[searchYear] = {};
        newBookingObject[searchYear][searchMonth] = {}
        //let newBookingList = getLocalDates({ date: newDate })?.avilability;

        let bookCall;
        if (!oldDateCheck(newDate, fixedTodayDate)) {
            try { bookCall = await getOcupedDates({ date: newDate }) }
            catch (error) { bookCall = undefined }
        }

        let newBookingList = bookCall?.data.avilability;
        let avilability = newBookingList && newBookingList.length ? newBookingList : [];

        for (let i = 0; i < avilability.length; i++) {
            let apiDay = String(avilability[i].day);
            let availableBoungalows = avilability[i].totalBungalows - avilability[i].bungalowsOccuped;
            let state = setAvailableBungalow(availableBoungalows)
            newBookingObject[searchYear][searchMonth][apiDay] = {
                Date: `${apiDay}/${searchMonth}/${searchYear}`,
                State: state,
                AvailableBungalows: [
                    {
                        Type: "Type A1",
                        AvailableQuantity: availableBoungalows,
                        MaxPeople: 2
                    }
                ]
            }
        }

    }

    return newBookingObject;
}


export const UsedDateContex = React.createContext(initialDates)

export const UsedDateProvider = (props: widgetProps) => {

    const [usedDates, setUsedDates] = useState<any>()
    const user = useSelector((state: RootState) => state.user)

    let todayDate = new Date();
    let fixedTodayDate = `${+todayDate.getDate()}/${+todayDate.getMonth() + 1}/${todayDate.getFullYear()}`;
    let fixedPreviousMonthDate = previousMonthDate(fixedTodayDate)
    let fixedNextMonthDate = nextMonthDate(fixedTodayDate);
    let fixedBeforeNextMonth = nextMonthDate(fixedNextMonthDate)
    let newBookingObject: DatesType = {};
    //newBookingObject = addNewDate(fixedTodayDate, newBookingObject);
    //newBookingObject = addNewDate(fixedNextMonthDate, newBookingObject);
    
    const addApiDates = () =>{
        addNewDate(fixedTodayDate, newBookingObject, fixedTodayDate).then((response) => {
            newBookingObject = response
            setUsedDates({ ...newBookingObject })
            addNewDate(fixedNextMonthDate, newBookingObject, fixedTodayDate).then((response) => {
                newBookingObject = response
                setUsedDates({ ...newBookingObject })
                addNewDate(fixedPreviousMonthDate, newBookingObject, fixedTodayDate).then((response) => {
                    newBookingObject = response
                    setUsedDates({ ...newBookingObject })
                    addNewDate(fixedBeforeNextMonth, newBookingObject, fixedTodayDate).then((response) => {
                        newBookingObject = response
                        setUsedDates({ ...newBookingObject })
                    })
                })
            })
        })
    }

    const updateDatesHandler = (dates: NewDateUpdatedType) => {

        if (!dates.OneDay) {
            let newDates = [dates.StartDate, ...dates.BetweenDays];
            setUsedDates((prevState: any) => {
                for (let date of newDates) {
                    let [day, month, year] = date.split("/");
                    if (!prevState.hasOwnProperty(year)) prevState[year] = {};
                    if (!prevState[year].hasOwnProperty(month)) prevState[year][month] = {};

                    if (!prevState[year][month].hasOwnProperty(day)) {
                        let availableBoungalows = 9 - dates.Bungalows
                        let state = setAvailableBungalow(availableBoungalows)

                        prevState[year][month][day] = {
                            Date: date,
                            State: state,
                            AvailableBungalows: [
                                {
                                    Type: "Type A1",
                                    AvailableQuantity: availableBoungalows,
                                    MaxPeople: 2
                                }
                            ]
                        }
                    }
                    else {

                        let prevDateInfo = prevState[year][month][day];

                        for (let i = 0; i < prevDateInfo.AvailableBungalows.length; i++) {
                            let boungalow = prevDateInfo.AvailableBungalows[i];
                            if (boungalow.AvailableQuantity >= dates.Bungalows) {
                                boungalow.AvailableQuantity = boungalow.AvailableQuantity - dates.Bungalows;
                                if (boungalow.AvailableQuantity == 0) prevDateInfo.AvailableBungalows.splice(i, 1)
                                else prevDateInfo.AvailableBungalows[i] = boungalow;
                                break;
                            }
                        }

                        if (prevDateInfo.AvailableBungalows.length) {
                            let availableBoungalows = prevDateInfo.AvailableBungalows.reduce((previous: any, current: any) => previous + current.AvailableQuantity, 0);
                            let newState = setAvailableBungalow(availableBoungalows);
                            prevDateInfo.State = newState;
                        }
                        else {
                            prevDateInfo.State = "Full";
                        }
                    }
                }
                return prevState;
            })

        }

    }

    const createDatesHandler = (searchDate: string) => {
        //let newDates = addNewDate(searchDate, usedDates)
        //setUsedDates(newDates)
        let newState = usedDates ? { ...usedDates } : {}
        setUsedDates(newState)
        addNewDate(searchDate, usedDates, fixedTodayDate).then((response) => {
            setUsedDates({ ...response })
        }).catch((error) => {
            setUsedDates((prevState: any) => prevState)
        })
    }

    const reloadDatesHandler = (searchDate: string) => {
        let newState = usedDates ? { ...usedDates } : {}
        setUsedDates(newState)
    }

    const resetDatesHandler = () => {
        setUsedDates({});
        addApiDates()
    }

    const updateUsedDatesHandler = (newUsedDate:any) => {
        setUsedDates(newUsedDate)
    }

    useEffect(() => {
        addApiDates()
    }, [])

    return (
        <UsedDateContex.Provider value={{
            dates: usedDates,
            updateDates: updateDatesHandler,
            createDates: createDatesHandler,
            reloadDates: reloadDatesHandler,
            resetDates: resetDatesHandler,
            updateUsedDates: updateUsedDatesHandler
        }}>
            {props.children}
        </UsedDateContex.Provider>
    );
}

export default UsedDateContex;