import React from 'react';
import { useAppSelector } from '../../app/hooks'
import { selectUserInfo } from '../../services/user/userSlice';
import { useLocation } from "react-router-dom"
import { NavLink } from "react-router-dom"
import './sidebar.css'

import usersIcon from "../../assets/icons/users-icon.png"
import chatsIcon from "../../assets/icons/chats-icon.png"
import eventsIcon from "../../assets/icons/events-icon.png"
import collectionsIcon from "../../assets/icons/Collections.png"
import LogoutIcon from "../../assets/icons/logout.png"
import WhiteWallet from "../../assets/icons/whiteWallet2.png"
import MiniCalendar from "../../assets/icons/miniCalendar.png"
import ApplicationIcon from "../../assets/icons/applications/form.png"
import PaymentsIcon from "../../assets/icons/payments.png"


function Sidebar() {
  const userInfo = useAppSelector(selectUserInfo);
  const sampleLocation = useLocation();

  /*useLayoutEffect(() => {
    console.log(sampleLocation.pathname)
  }, [sampleLocation.pathname]);*/

  // useLayoutEffect(() => {
  //     console.log('Sidebar Reload userInfo')
  //     console.log(userInfo)
  // }, [userInfo]);

  const logOutHandler = () => {
    console.log("logout required")
  }

  return (
    <div className="SideBar">
      <div className='sidebar-upper'>

        <div className='sidebar-header'>
          <p className='sidebar-title'> Admin Dashboard </p>
          <p className='sidebar-subtitle' > vinereserveclub </p>
          <div className='sidebar-ruler'></div>
        </div>

        <div className='sidebar-navigation'>
          <NavLink to="/users">
            <div className={sampleLocation.pathname === '/users' ? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
              <img
                className='sidebar-option-icon'
                src={usersIcon}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'>Users</div>
            </div>
          </NavLink>

          <NavLink to="/events">
            <div className={sampleLocation.pathname === '/events' ? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
              <img
                className='sidebar-option-icon'
                src={eventsIcon}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'> Events </div>
            </div>
          </NavLink>

          <NavLink to="/collections">
          <div className={sampleLocation.pathname.match(/\/collections$|\/collections\//)? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
            {/* <div className={sampleLocation.pathname === '/collections'? 'sidebar-option sidebar-option-active' : 'sidebar-option'} > */}
              <img
                className='sidebar-option-icon'
                src={collectionsIcon}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'> Collections </div>
            </div>
          </NavLink>

          <NavLink to="/wallets">
          <div className={sampleLocation.pathname.match(/\/wallets$|\/wallets\//)? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
              <img
                className='sidebar-option-icon'
                src={WhiteWallet}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'> Wallets </div>
            </div>
          </NavLink>

          <NavLink to="/reservations">
          <div className={sampleLocation.pathname.match(/\/reservations$|\/reservations\//)? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
              <img
                className='sidebar-option-icon'
                src={MiniCalendar}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'> Reservations </div>
            </div>
          </NavLink>

          <NavLink to="/applications">
          <div className={sampleLocation.pathname.match(/\/applications$|\/applications\//)? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
              <img
                className='sidebar-option-icon'
                src={ApplicationIcon}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'> Applications </div>
            </div>
          </NavLink>

          <NavLink to="/payments">
            <div className={sampleLocation.pathname.match(/\/payments$|\/payments\//)? 'sidebar-option sidebar-option-active' : 'sidebar-option'} >
              <img
                className='sidebar-option-icon'
                src={PaymentsIcon}
                alt='sidebar-option-icon'
              />
              <div className='sidebar-option-title'> Payments </div>
            </div>
          </NavLink>

        </div>

      </div>

      <div className='sidebar-footer'>
        <div className='sidebar-ruler endRuler' />
        <div className='sidebar-userinfo'>

          <div className='sidebar-userinfo-container'>
            <p className='sidebar-userinfo-username' >
              {userInfo.data ? `@${userInfo.data.username}` : 'loading...'}
            </p>
            <p className='sidebar-userinfo-email' >
              {userInfo.data ? userInfo.data.email : 'loading...'}
            </p>
          </div>

          <div className="sidebar-userinfo-logo" >
            <img src={LogoutIcon} alt="logout" onClick={logOutHandler}/>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Sidebar;
