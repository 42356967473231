import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDropmenuOptions } from "../../services/collections/collectionReq";
import { getWalletInfo, localGetWalletInfo, validateWalletAddress } from "../../services/wallets/walletsReq";

import { TResponseGet, TResponseCreate } from '../../services/collections/collectionReq'
import styled from 'styled-components';

import WalletImageComponent from '../walletFormElements/walletImage'
import WalletNameComponent from '../walletFormElements/walletName'
import ButtonsArea from '../walletFormElements/buttonsArea'
import AddressAndCategory from "../walletFormElements/addressAndCategory";
import ModalNotification from "../modals/modalNotification";


interface widgetProps {
    type: string
    handleSave: Function,
    handleDelete?: Function
};

export interface TWalletData {
    id?: string,
    WalletImage: string,
    WalletName: string,
    WalletAddress: string,
    Category: string,
    Validated: boolean
}

const emptyWallet: TWalletData = {
    WalletImage: "",
    WalletName: "",
    WalletAddress: "",
    Category: "",
    Validated: false
}

interface TWalletImageData {
    url: string,
    file: any
}
export interface TImageFiles {
    WalletImageData: TWalletImageData
}

const emptyImageFiles = {
    WalletImageData: {
        url: "",
        file: undefined
    }
}

const requiredFields: Array<string> = ["WalletName", "WalletAddress", "Category"]
const errorMesages: Object = {
    "WalletName": "Write a Name for this Wallet",
    "WalletAddress": "Add a Wallet Address",
    "Category": "Select a Category for this Wallet", 
    "Validation": "Wrong Wallet Address, please use a valid one to continue",
}
const validationConfirmedText = "Wallet validated successfully, Redirecting...";

const checkRequired = (collection: TWalletData) => {
    let activateAlert = { state: false, element: "" };
    let newCollection: any = { ...collection }
    for (let key in collection) {
        if (requiredFields.includes(key)) {
            if (!newCollection[key] || !newCollection[key].length) {
                activateAlert.state = true;
                activateAlert.element = key;
                break;

            }
        }
    }
    return activateAlert;
}

const emptyOptions = {
    "currencies": ["ETH"],
    "category": ["Wine"],
    "creator_wallet": ["iReserve - A54B"],
    "currencies_status": ["enabled"],
    "category_status": ["enabled"],
    "creator_wallet_status": ["enabled"]
}



export default function WalletForm(props: widgetProps) {

    let [imageFiles, setImageFiles] = useState<TImageFiles>(emptyImageFiles)
    let [walletData, setWalletData] = useState(emptyWallet);
    let [alert, setAlert] = useState({ state: false, element: "" });
    let [sucessAlert, setsucessAlert] = useState(false);
    let [dropMenuOption, setDropMenuOptions] = useState<{ category: any }>(emptyOptions);
    let [validationModalState, setValidationModalState] = useState(false);
    let navigate = useNavigate();


    let [categoryFlag, setCategoryFlag] = useState(false)
    let updateCategoryFlag = () => setCategoryFlag(prevState => !prevState)

    let { id } = useParams()

    useEffect(() => {
        setImageFiles(emptyImageFiles)
        setAlert({ state: false, element: "" })

        if ((props.type === "edit") && id) {
            getWalletInfo(id).then((response: TWalletData) => {
            //localGetWalletInfo(id).then((response: TWalletData) => {

                let initialImagesData = {
                    WalletImageData: {
                        url: response.WalletImage,
                        file: undefined
                    }
                }
                //response.Validated = true;
                setWalletData(response);
                setImageFiles(initialImagesData)
            }).catch((error) => { console.log("error: " + error) })
        }

        getDropmenuOptions().then((response: any) => {
            let newResponse = response.currencies ? response : emptyOptions;
            console.log(newResponse)
            let dropMenuOptions = {
                "category": newResponse.category.map((currency: string, index: number) => { return { name: currency, active: newResponse["category_status"][index] === "enabled" } }),
            }
            setDropMenuOptions(dropMenuOptions)
        })

    }, []);



    const updateWalletAttributes = (property: string, value: string | Array<string> | boolean) => {
        setWalletData((prevState: TWalletData) => {
            let newState: TWalletData | any = { ...prevState };
            newState[property] = value;
            return newState;
        })
    }


    // Reset dropmenu state when clicking outside
    const resetDropStates = (event: any) => {
        let clickedCurrentInputOptions = event.target.classList.value.match(/(?<=option-)\d+|(?<=selection-)\d+/);
        if (!clickedCurrentInputOptions) {
            if (categoryFlag) {
                setCategoryFlag(false);
            }
        }
    }


    const validateAddress = (walletAddres: string) => {
        /*const test = new Promise<string>((resolve, reject) => {
            resolve("Correct validation")
        })
        return test;*/

        const walletPayload = {
            type: "verifyWallet",
            wallet_address: walletAddres
        }
        return validateWalletAddress(walletPayload)
    }


    const validationConfirmation = () => {
        return new Promise<void>((resolve, reject) => {
            validateAddress(walletData.WalletAddress).then((resp: any) => {
                setValidationModalState(false)
                setAlert({state:false, element:""})
                console.log(resp);
                if (resp.data.isValid) {
                    setsucessAlert(true)
                    let updatedWalletData = { ...walletData };
                    updatedWalletData.Validated = true;
                    let saveParameters = {
                        idWallet: id,
                        walletData: updatedWalletData,
                        walletImageFiles: imageFiles,
                    }
                    props.handleSave(saveParameters)
                    setValidationModalState(false)
                }
                else{
                    setAlert({state:true, element:"Validation"})
                }
            })
        })
    }


    //===== Buttons
    const handleSave = () => {
        let activateAlert = checkRequired(walletData);
        setAlert(activateAlert);
        if (!activateAlert.state) {

            if (walletData.Validated) {
                let saveParameters = {
                    idWallet: id,
                    walletData: walletData,
                    walletImageFiles: imageFiles,
                }
                props.handleSave(saveParameters)
            }

            else {
                setValidationModalState(true)
            }
        }

    }

    const handleCancel = () => { navigate("/wallets") };
    const modalVariables = {
        acceptText: "Validate",
        cancelText: "Cancel",
        closeModal: () => { setValidationModalState(false) },
        confirmModal: validationConfirmation,
        message: "The address will be validated before saving, please click on validate once you are sure of the address"
    }

    return (
        <FormWallet onClick={resetDropStates}>
            {validationModalState && <ModalNotification {...modalVariables} />}
            <Title><h2>{props.type === "edit" ? "EDIT" : 'CREATE'} WALLET</h2></Title>
            <RequiredAdd> <p><PurpleDot /> Required fields </p></RequiredAdd>
            <WalletImageComponent walletData={walletData} updateWalletAttributes={updateWalletAttributes} updateImageFiles={(data: TImageFiles) => setImageFiles(data)} imageFiles={imageFiles} />
            <WalletNameComponent walletData={walletData} updateWalletAttributes={updateWalletAttributes} />
            <AddressAndCategory walletData={walletData} updateWalletAttributes={updateWalletAttributes} categoryFlag={categoryFlag} updateCategoryFlag={updateCategoryFlag} dropOptions={dropMenuOption || []} />
            {alert.state && <ErrorMessage>*{`${errorMesages[alert.element as keyof Object]}`}</ErrorMessage>}
            {sucessAlert && <SuccessMessage>*{validationConfirmedText}</SuccessMessage>}
            <ButtonsArea walletData={walletData} handleSave={handleSave} handleCancel={handleCancel} />

        </FormWallet>
    );
}



const FormWallet = styled.div`
    box-sizing: border-box;
    padding: 40px 20px 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    width: 100%;
    max-width: 900px;
`

const Title = styled.div`
    & h2{
        font-family: 'trade-gothic';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        color: #830F5B;
        margin: 0;
    }
`

const RequiredAdd = styled.div`
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    & p{
        margin: 0;
    }
`

const PurpleDot = styled.span`
    &::before{
        color: #830F5B;
        content: "*";
    }
`

const Draft = styled.div`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;
`
const DraftText = styled.p<{ state: boolean }>`
    color: ${({ state }) => state ? "#84004C" : "#9B9B9B"};
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 26.5497px;
    line-height: 32px;
    margin: 0px;
`

const ErrorMessage = styled.p`
    margin: 0;
    color: #c71111;
    flex: 1;
    text-align: left;
`
const SuccessMessage = styled.p`
    margin: 0;
    color: #12c71b;
    flex: 1;
    text-align: left;
`